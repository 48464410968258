import { SearchPostulant } from '../../views/OnlineEnrollment/interfaces/online';
import { TUITION_PROCESS } from '../endpoints';
import request from '../request';

/**
 * Retorna la información inicial para los pasos del proceso de matrícula
 */
export const getAllTuitionProcess = () => {
  return request<SearchPostulant>(TUITION_PROCESS.TUITION_PROCESS_STEPS);
};
