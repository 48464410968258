import { SelectOptionType } from '@octano/global-ui';

interface Options {
  id: string;
  name: string;
  [key: string]: any;
}

/**
 * Función para formatear las opciones del select de países
 * @param data Array con países {id, name}
 * @returns Array de opciones con los valores (value, label)
 */
export function formatOptions(data: Options[]): SelectOptionType[] {
  return data.map(
    (row: Options): SelectOptionType => ({
      value: row.id,
      label: row.name,
    }),
  );
}

/**
 * Función para formatear las opciones del select de países
 * @param data Array con países {id, name}
 * @returns Array de opciones con los valores (value, label, isoCode)
 */
export function formatCountryWithFlagsOptions(
  data: Options[],
): SelectOptionType[] {
  return data.map(
    (row: Options): SelectOptionType => ({
      value: row.id,
      label: row.name,
      isoCode: row.id,
    }),
  );
}

export function getSelectedOption(
  id: string | number | undefined,
  options: SelectOptionType[],
) {
  return options.find((row) => row.value === id);
}
