import { ReactElement, useCallback } from 'react';

import { getStudyPlanQuotaById } from '../../../api/requests/tuitionProcess';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { createFetchContext } from '../../../providers/FetchContextFactory';
import { StudyPlanQuotaResponse } from '../../../types/tuitionProcessResponseTypes';

export interface RegistrationNoSuaLoaderProps {
  studyPlanVersionOfferPeriodAdmissionId: number;
  children: (props: {
    data: StudyPlanQuotaResponse;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  StudyPlanQuotaResponse
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function RegistrationNoSuaLoader({
  studyPlanVersionOfferPeriodAdmissionId,
  children,
}: RegistrationNoSuaLoaderProps) {
  const request = useCallback(async () => {
    return getStudyPlanQuotaById(studyPlanVersionOfferPeriodAdmissionId);
  }, [studyPlanVersionOfferPeriodAdmissionId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }
          if (!data.studyPlanVersion) {
            return (
              <DisplayError
                insideCard
                textBody="No hay una oferta diponible"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            data,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
