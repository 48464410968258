import {
  Alert,
  Box,
  Button,
  addToast,
  showDialogInfo,
} from '@octano/global-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { format as formatRut } from 'rut.js';

import { useLoadingState } from '../../../../../hooks/useLoadingState';
import { useStepState } from '../../../../../hooks/useStepState';
import { useUserState } from '../../../../../hooks/useUserState';
import { moneyFormatter } from '../../../../../utils/currency';
import {
  finishPaymentProcess,
  generatePaymentLink,
  voucherPreview,
  voucherToken,
} from '../requests';
import { GatewayPaymentLinkResponse, GatewayPaymentResponse } from '../types';
import GatewayRedirect, { GatewayRedirectMethods } from './GatewayRedirect';
import PaymentTable from './PaymentTable';

export interface PaymentProps extends GatewayPaymentResponse {}

export default function Payment({ ...props }: PaymentProps) {
  const prefix = 'tuitionProcessNoSua.payment';

  const gatewatRedirectRef = useRef<GatewayRedirectMethods>(null);

  const methods = useForm();
  const { t } = useTranslation();

  const { setLoading } = useLoadingState();
  const { setIsSessionExpired } = useUserState();
  const { nextStep } = useStepState();
  const { handleSubmit, formState } = methods;

  const [downloading, setDownloading] = useState<boolean>(false);
  const [finishing, setFinishing] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [creatingLink, setCreatingLink] = useState<boolean>(false);

  const onSubmit = useCallback(async () => {
    if (creatingLink) {
      return;
    }
    setCreatingLink(true);
    setLoading(true);
    const { data, error } = await generatePaymentLink(window?.location?.href);
    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errorOnGenerate`),
      });
    } else if ((data as any)?.finishedTuition) {
      nextStep();
    } else if (data) {
      addToast({
        icon: 'check',
        color: 'success',
        text: t(`${prefix}.successOnGenerate`),
      });
      gatewatRedirectRef?.current?.redirect(data as GatewayPaymentLinkResponse);
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errorOnGenerate`),
      });
    }
    setCreatingLink(false);
  }, [creatingLink, nextStep, setLoading, t]);

  const handlCompleteProcesss = useCallback(async () => {
    try {
      if (finishing) {
        return;
      }
      setFinishing(true);
      const { error } = await finishPaymentProcess();
      setFinishing(false);
      if (error) {
        throw error;
      }
      setCompleted(true);
    } catch (error: any) {
      setFinishing(false);
      if (error && error.code === 'HTTP_ERROR' && error.status === 401) {
        setIsSessionExpired(true);
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnFinish`),
        });
      }
    }
  }, [finishing, setIsSessionExpired, t]);

  const handleFinishProcesss = useCallback(async () => {
    nextStep();
  }, [nextStep]);

  const handleDownloadVoucher = useCallback(async () => {
    try {
      if (downloading) {
        return;
      }
      setDownloading(true);
      const { data: token, error: errorToken } = await voucherToken();
      if (errorToken) {
        throw errorToken;
      } else if (!token) {
        throw new Error('NO_TOKEN');
      }

      const url = voucherPreview(token);
      setDownloading(false);
      window.open(url);
    } catch (error: any) {
      setDownloading(false);
      if (error && error.code === 'HTTP_ERROR' && error.status === 401) {
        setIsSessionExpired(true);
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnDownload`),
        });
      }
    }
  }, [downloading, setIsSessionExpired, t]);

  const showNotAvailableTuition = useCallback(() => {
    showDialogInfo({
      icon: {
        name: 'information',
        color: 'primary',
      },
      title: t(`${prefix}.modalNotAvailableTuition.title`),
      subtitle: t(`${prefix}.modalNotAvailableTuition.subtitle`, {
        email: props?.helpEmail,
        phone: props?.helpPhone,
      }),
      btnConfirm: {
        text: t(`${prefix}.modalNotAvailableTuition.btn`),
        color: 'primary',
        onConfirm: () => {},
      },
    });
  }, [props?.helpEmail, props?.helpPhone, t]);

  useEffect(() => {
    if (props?.showNotAvailableTuitionModal) {
      showNotAvailableTuition();
    }
  }, [props?.showNotAvailableTuitionModal, showNotAvailableTuition]);

  useEffect(() => {
    if (props?.receipt && !finishing && !completed) {
      handlCompleteProcesss();
      setCompleted(true);
    }
  }, [props?.receipt, completed, finishing, handlCompleteProcesss]);

  return (
    <>
      <Form className="mt-5 pt-5" onSubmit={handleSubmit(onSubmit)}>
        {!!(props?.message?.trim() && !finishing) && (
          <Alert
            isOpen={true}
            text={props?.message?.trim()}
            className="mx-auto"
            style={{ maxWidth: 370 }}
            icon="information"
            fade
          />
        )}
        <Row className="pb-5 mb-5 justify-content-center">
          {Boolean(props?.details) && (
            <Box
              body={
                <div className="d-flex flex-column">
                  <span className="fs-14 fw-400">
                    {t(`${prefix}.availablePaymentsFor`)}
                  </span>
                  <span className="text-primary fw-400 fs-16 text-uppercase">
                    {props?.details?.student?.name}
                  </span>
                  <span className="text-primary fw-700 fs-16 text-uppercase">
                    {props?.details?.student?.rut
                      ? formatRut(props?.details?.student?.rut)
                      : props?.details?.student?.passport ?? ''}
                  </span>
                </div>
              }
              color="default"
              variant="standard"
              style={{ maxWidth: 370 }}
              className="text-center fw-400 w-100"
            />
          )}
          {Boolean(props?.receipt) && (
            <Box
              body={
                <Row>
                  <Col xs={12}>
                    {Object.entries(props?.receipt ?? {})
                      ?.filter(([, value]) =>
                        Array.isArray(value)
                          ? !!value?.length
                          : !!value?.trim(),
                      )
                      ?.map(([key, value]) => (
                        <Row key={key} className="my-2">
                          <Col xs={12} md={6}>
                            <span className="text-light fw-400 fs-16 w-100">
                              {t(`${prefix}.receipt.${key}`)}
                            </span>
                          </Col>
                          <Col xs={12} md={6}>
                            {key === 'amount' ? (
                              <span className="text-primary fw-700 fs-16 w-100">
                                {moneyFormatter().format(value ?? 0)}
                              </span>
                            ) : (
                              <span className="text-primary fw-700 fs-16 w-100">
                                {Array.isArray(value)
                                  ? value
                                      ?.map((e) => t(`${prefix}.table.${e}`))
                                      ?.join(', ')
                                  : value}
                              </span>
                            )}
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>
              }
              color="default"
              variant="standard"
              style={{ maxWidth: 370 }}
              className="fw-400 w-100"
            />
          )}
        </Row>

        {Boolean(props?.details?.items?.length) && (
          <PaymentTable data={props?.details?.items ?? []} loading={false} />
        )}
        {Boolean(props?.details) && (
          <Row className="pt-5 mt-5 justify-content-center">
            <Col xs={12}>
              <Button
                type="submit"
                style={{ maxWidth: 348 }}
                text={
                  !props?.details?.total
                    ? t(`${prefix}.finishCourseRegistration`)
                    : t(`${prefix}.submit`)
                }
                loading={formState.isSubmitting || creatingLink}
                disabled={!!props?.showNotAvailableTuitionModal}
                className="mx-auto"
                fullwidth
              />
            </Col>
            <Col xs={12}>
              <span className="w-100 d-block text-center fs-14 mt-2 text-light">
                {t(`${prefix}.hint`, {
                  amount: `${moneyFormatter().format(
                    props?.details?.total ?? 0,
                  )}`,
                })}
              </span>
            </Col>
          </Row>
        )}
        {Boolean(props?.receipt) && (
          <Row className="pt-5 mt-5 justify-content-center">
            <Col xs={12}>
              <Button
                type="button"
                style={{ maxWidth: 348 }}
                text={t(`${prefix}.finishCourseRegistration`)}
                className="mx-auto"
                loading={finishing}
                fullwidth
                onClick={handleFinishProcesss}
              />
            </Col>
            <Col xs={12}>
              <Button
                type="button"
                icon="download"
                style={{ maxWidth: 348 }}
                text={t(`${prefix}.donwloadReceipt`)}
                className="mx-auto mt-2"
                loading={downloading}
                disabled={finishing}
                fullwidth
                outlined
                onClick={handleDownloadVoucher}
              />
            </Col>
          </Row>
        )}
      </Form>

      <GatewayRedirect ref={gatewatRedirectRef} />
    </>
  );
}
