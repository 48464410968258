import { Box, Button } from '@octano/global-ui';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { ReactComponent as ResumenImage } from '../../../../assets/svg/with_tuition_process.svg';
import { Student } from '../../interfaces/online';

interface SearchStudentOnSiteMultiTuitionProps {
  students: Student[];
  preload: () => void;
}

export const SearchStudentOnSiteMultiTuition = ({
  students,
  preload,
}: SearchStudentOnSiteMultiTuitionProps) => {
  const prefix = 'StudentOnline.multiTuition';
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Row className="text-center py-4">
      <Col xs={12}>
        <ResumenImage width="230px" />
      </Col>

      <Col md={12}>
        <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-2">
          {t(`${prefix}.title`)}
        </h2>
        <p className="fs-18 fw-300">
          <Trans t={t}>{t(`${prefix}.subtitle`)}</Trans>
        </p>
      </Col>

      <Col xs={12}>
        {students &&
          students.length > 0 &&
          students.map((student) => (
            <Col xs={12} key={student?.Details_id ?? student?.postulant_id}>
              <Box
                className="mt-2"
                style={{ minWidth: 400, maxWidth: 500 }}
                body={
                  <Row>
                    <Col xs={12} className="text-left">
                      <p className="fw-700 text-uppercase m-0">Programa</p>
                    </Col>
                    <Col xs={12} className="text-left">
                      <p className="m-0">{student.studyPlanVersion_name}</p>
                    </Col>
                  </Row>
                }
                title=""
                color="secondary"
                variant="outlined"
              />
            </Col>
          ))}
      </Col>

      <Col xs={12} className="mt-4">
        <p className="fs-18 fw-300 mt-4">{t(`${prefix}.body`)}</p>
        <Button
          className="mx-auto mt-4"
          style={{ maxWidth: 694 }}
          text={t(`${prefix}.actions.start`)}
          onClick={() => history.replace('/tuition-process/steps')}
          fullwidth
        />
      </Col>
    </Row>
  );
};
