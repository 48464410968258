import { IInput } from '../../components/modal/UploadModal';
import { CompletedStepResponse } from '../../types/completedTypes';
import { CosignerBody, CosignerStepResponse } from '../../types/cosignerTypes';
import {
  LoginTuitionProcessBody,
  LoginUnauthorizedType,
} from '../../types/loginTypes';
import { SavePostulantBody } from '../../types/postulantTypes';
import {
  SignDocumentationBody,
  SignDocumentationIdentityCardUpload,
  SignDocumentationStepResponse,
} from '../../types/signDocumentationTypes';
import { StudentFileResponse } from '../../types/studentFileSectionTypes';
import {
  SustainerStepResponse,
  SustainerTypeResponse,
} from '../../types/sustainerTypes';
import {
  Payday,
  TariffData,
  TariffFormData,
  TariffPaymentTypeCodesEnum,
} from '../../types/tariffTypes';
import { TermsAndConditionsStepResponse } from '../../types/termsAndConditionsTypes';
import {
  StudyPlanQuotaResponse,
  TuitionProcessStatusResponse,
  WaitingListInformationResponse,
} from '../../types/tuitionProcessResponseTypes';
import { baseURL } from '../api';
import { POSTULATION, STUDY_PLANS, TUITION_PROCESS } from '../endpoints';
import request, {
  AxiosErrorWithResponse,
  AxiosResult,
  AxiosResultDefaultError,
  RequestOptionsType,
} from '../request';

/**
 * Valida si existe un proceso de matrícula activo y si existe
 * retorna el proceso más próximo.
 * Si no existe retorna el correo de contacto del proceso de matrícula.
 */
export const getActiveTutionProcess = async () => {
  const result = await request(TUITION_PROCESS.ACTIVE_TUITION_PROCESS);
  if (result.error) {
    throw result.error.error;
  }
  return result.data;
};

export type LoginTuitionProcessError =
  | {
      code: 'NOT_IN_PROCESS' | 'IN_WAITING_LIST';
      data: LoginUnauthorizedType;
    }
  | { code: 'invalidFields' }
  | { code: 'notFound' }
  | { code: 'unknownError' };

type LoginTuitionProcessResult = AxiosResult<
  { accessToken: string },
  LoginTuitionProcessError
>;
/**
 * Valida si un postulante tiene acceso al proceso de matrícula
 */
export const loginTutionProcess = async (
  data: LoginTuitionProcessBody,
): Promise<LoginTuitionProcessResult> => {
  const result = await request(TUITION_PROCESS.LOGIN, {
    method: 'post',
    data,
  });
  if (result.error) {
    const resultError = result.error;
    if (resultError.code === 'HTTP_ERROR') {
      const state = resultError.data?.state;
      if (
        resultError.status === 401 &&
        (state === 'NOT_IN_PROCESS' || state === 'IN_WAITING_LIST')
      ) {
        return {
          error: {
            code: state,
            data: resultError.data as LoginUnauthorizedType,
          },
        };
      } else if (
        resultError.status === 400 ||
        (resultError.status === 401 && state === 'BAD_CREDENTIALS')
      ) {
        return {
          error: {
            code: 'invalidFields',
          },
        };
      } else if (
        resultError.status === 404 &&
        resultError.data.error === 'Not Found'
      ) {
        return {
          error: {
            code: 'notFound',
          },
        };
      }
    }
    return {
      error: { code: 'unknownError' },
    };
  }
  return result;
};

export const refreshTokenTuitionProcess = async () => {
  const result = await request<{
    accessToken: string;
  }>(TUITION_PROCESS.REFRESH_TOKEN, {
    method: 'post',
  });

  return result;
};

export const searchPostulant = async (data: {
  rut?: string;
  passportNumber?: string;
  passportCountry?: string;
  hasPassport?: boolean;
}) => {
  return request<any>(TUITION_PROCESS.POSTULANT_SEARCH, {
    method: 'post',
    data,
  });
};

export type AuthenticationError = {
  code: 'AUTHENTICATION';
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

export async function requestAuth<T>(
  url: string,
  options?: RequestOptionsType,
): Promise<AxiosResult<T, AuthenticationError | AxiosResultDefaultError>> {
  const response = await request<T>(url, options);
  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 401) {
      return {
        error: {
          ...response.error,
          code: 'AUTHENTICATION',
        } as AuthenticationError,
      };
    } else {
      return response;
    }
  }
  return {
    data: response.data,
  };
}

/**
 * Retorna la información inicial para los pasos del proceso de matrícula
 */
export const getStatusTuitionProcess = (
  postulationDetailId?: string | number,
) => {
  return request<TuitionProcessStatusResponse>(
    TUITION_PROCESS.TUITION_PROCESS_STEPS_BY_POSTULATION_DETAIL(
      postulationDetailId,
    ),
  );
};

/**
 * Obtiene la información del paso "Ficha Alumno" del proceso de matrícula
 */
export const getStudentFile = () => {
  return request<StudentFileResponse>(TUITION_PROCESS.STUDENT_FILE);
};

/**
 * Guarda la información del paso "Ficha Alumno"  del proceso de matrícula
 */
export const saveStudentFile = (data: any) => {
  return request(TUITION_PROCESS.STUDENT_FILE, { method: 'post', data });
};

/**
 * Guarda la información del paso "Ficha Alumno"  del proceso de matrícula
 */
export const saveStudentFilePhoto = (
  postulationDetailId?: number | string,
  photo?: File,
) => {
  const formData = new FormData();
  if (photo) {
    formData.append('photo', photo);
  }
  let url = `${TUITION_PROCESS.STUDENT_FILE}/photo`;
  if (postulationDetailId) {
    url = `${TUITION_PROCESS.STUDENT_FILE}/${postulationDetailId}/photo`;
  }
  return requestAuth(url, {
    method: 'post',
    data: formData,
  });
};

export const changeDesistedStatusToInProcess = () => {
  return request(`${TUITION_PROCESS.TUITION_PROCESS_STEPS}/status`, {
    method: 'put',
  });
};

export const changeToDesisted = () => {
  return request(`${TUITION_PROCESS.TUITION_PROCESS_STEPS}/status/desisted`, {
    method: 'put',
  });
};

export const changeToWaiting = () => {
  return request(`${TUITION_PROCESS.TUITION_PROCESS_STEPS}/status/waiting`, {
    method: 'put',
  });
};

/**
 * PASO DEL SUSTENTADOR
 */

//para traer opciones de selectores en el paso del sustentador
export const getSustainerFormData = () => {
  return requestAuth<SustainerTypeResponse>(
    TUITION_PROCESS.TARIFF_QUOTAS_FORM_DATA,
  );
};

//Obtiene la información asociada al paso "Datos Sustentador" del proceso de matrícula
export const getSustainer = () => {
  return request<SustainerStepResponse>(TUITION_PROCESS.SUSTAINER);
};

/**
 * Guarda la información asociada al paso "Datos Sustentador" del proceso de matrícula
 */
export const saveSustainer = (data: any) => {
  return request(TUITION_PROCESS.SUSTAINER, { method: 'post', data });
};

/**
 * PASO DEL AVAL
 */

/**
 * Obtiene la información asociada al paso "Datos Aval" del proceso de matrícula
 */
export const getCosigner = () => {
  return request<CosignerStepResponse>(TUITION_PROCESS.COSIGNER);
};

/**
 * Guarga la información asociada al paso "Datos Aval" del proceso de matrícula
 */
export const saveCosigner = (data: CosignerBody) => {
  return request(TUITION_PROCESS.COSIGNER, { method: 'post', data });
};

/**
 * Obtiene la información asociada al paso "Términos y Condiciones" del proceso de matrícula
 */
export const getTermsAndConditions = () => {
  return request<TermsAndConditionsStepResponse>(
    TUITION_PROCESS.TERMS_AND_CONDITIONS,
  );
};

/**
 * Obtiene el token para poder obtener el documento de los términos y condiciones
 * @returns string
 */
export const tokenPreviewDocument = () => {
  return request<string>(TUITION_PROCESS.TERMS_AND_CONDITIONS_PREVIEW_TOKEN, {
    method: 'post',
  });
};

/**
 * Obtiene la url para abrir el documento pdf asociado a los terminos y condiciones
 * @param token
 * @returns
 */
export const getDocumentPreview = (token: string): string => {
  return `${baseURL}${TUITION_PROCESS.TERMS_AND_CONDITIONS_PREVIEW}?token=${token}`;
};

/**
 * Guarda que se finaliza el paso de términos y condiciones
 * @returns
 */
export const saveTermsAndConditions = () => {
  return request(TUITION_PROCESS.TERMS_AND_CONDITIONS, {
    method: 'post',
  });
};

/**
 * Obtiene la información asociada al paso "Pago Matrícula"
 */
export const getPayment = () => {
  return request<{ studentFullName: string; isPaid: boolean }>(
    TUITION_PROCESS.PAYMENT,
  );
};

/**
 * Guarga el pago de la matrícula como realizado
 */
export const savePayment = () => {
  return request(TUITION_PROCESS.PAYMENT, { method: 'post' });
};

/**
 * Obtiene la información asociada al paso "Firmar Documentación"
 */
export const getSignDocumentation = () => {
  return request<SignDocumentationStepResponse>(
    TUITION_PROCESS.SIGN_DOCUMENTATION,
  );
};

/**
 * Firmar la documentación
 */
export const signDocumentation = (data: SignDocumentationBody) => {
  return request(TUITION_PROCESS.SIGN_DOCUMENTATION_SIGN, {
    method: 'post',
    data,
  });
};

/**
 * Subir CI por frente y por detrás
 */
export const uploadIdentityCard = (
  data: SignDocumentationIdentityCardUpload,
) => {
  const formData = new FormData();
  formData.append('type', data.type);
  formData.append('front', data.front, data.front.name);
  formData.append('back', data.front, data.front.name);
  return request(TUITION_PROCESS.SIGN_DOCUMENTATION_IDENTITY_CARD_UPLOAD, {
    method: 'post',
    data: formData,
  });
};

/*
 * Verificar la firma de los documentos y la carga de los CI
 */
export const verifySignDocumentation = () => {
  return request(TUITION_PROCESS.SIGN_DOCUMENTATION_VERIFY, { method: 'post' });
};

/**
 * Obtiene la información asociada al paso "Completado"
 */
export const getCompleted = () => {
  return request<CompletedStepResponse>(TUITION_PROCESS.COMPLETED);
};

/**
 * Guarda la información de un postulante para que pueda ingresar al
 * proceso de matrícula
 * @param data
 * @returns
 */
export const requestSavePostulant = (data: SavePostulantBody) => {
  return request<{ accessToken: string }>(
    TUITION_PROCESS.POSTULANT_REGISTRATION,
    {
      method: 'post',
      data,
    },
  );
};

/**
 * Guarda la información de un postulante y carrera.
 * Salta directo al paso dos de postulación
 */
export const requestSavePostulantAndCareer = (data: SavePostulantBody) => {
  return request<{ accessToken: string }>(
    TUITION_PROCESS.POSTULANT_REGISTRATION_WITH_CAREER,
    {
      method: 'post',
      data,
    },
  );
};

/**
 * Obtiene listado de planes de estudio con sus cupos disponibles
 * para el proceso de admisión SIN SUA
 */
export const getStudyPlanQuota = () => {
  return request<StudyPlanQuotaResponse[]>(STUDY_PLANS.STUDY_PLAN_QUOTA);
};

export const getStudyPlanQuotaById = (id: string | number) => {
  return request<StudyPlanQuotaResponse>(
    STUDY_PLANS.STUDY_PLAN_QUOTA_BY_ID(id),
  );
};

/**
 * Guarda la selección de carrera para un alumno en proceso SIN SUA
 * @param data
 * admissionId es el id de la carrera/campus/jornada seleccionada
 * acceptsWaitingList si el usuario confirma quedar en lista de espera
 * @returns
 */
export const requestSaveCareerSelection = (data: {
  admissionId: number;
  acceptsWaitingList: boolean;
}) => {
  return request<{
    admissionType: { id: string };
    id: number;
    postulantId: number;
    details: { id: number; status: string; admissionId: number }[];
  }>(POSTULATION.CREATE, { method: 'post', data });
};

/**
 * Actualiza la selección de carrera para un alumno en proceso SIN SUA
 * @param data
 * admissionId es el id de la carrera/campus/jornada seleccionada
 * acceptsWaitingList si el usuario confirma quedar en lista de espera
 * sub pertenece al postulantId
 * @returns
 */
export const requestUpdateCareerSelection = (data: {
  admissionId: number;
  acceptsWaitingList: boolean;
  sub?: number;
}) => {
  return request<{
    admissionType: { id: string };
    id: number;
    postulantId: number;
    details: { id: number; status: string; admissionId: number }[];
  }>(POSTULATION.UPDATE, { method: 'put', data });
};

/**
 * Obtiene información del estudiante en lista de espera
 * y email de ayuda
 * @returns
 */
export const requestWaitingListInformation = (admissionId?: number) => {
  return request<WaitingListInformationResponse>(
    POSTULATION.INFORMATION_WAITING_LIST(admissionId),
    { method: 'get' },
  );
};

export const uploadPostulationProcessDocument = (
  uploadMethod: string,
  code: string,
  values?: IInput,
) => {
  let url =
    TUITION_PROCESS.SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION(uploadMethod);
  let formData: FormData | Record<string, any>;
  if (['signature', 'confirmation'].includes(uploadMethod)) {
    formData = { type: code };
  } else {
    url = TUITION_PROCESS.SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION(uploadMethod);

    formData = new FormData();
    formData.append('type', code);

    if (values) {
      Object.entries(values).forEach(([key, value]) => {
        if (value instanceof File) formData.append(key, value, value.name);
        else formData.append(key, value);
      });
    }
  }

  return request(url, {
    method: 'post',
    data: formData,
  });
};

/**
 * Obtiene el token para poder obtener documento para firmar la documentación
 * @returns string
 */
export const tokenSignPreviewDocument = (code: string) => {
  return request<string>(TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW_TOKEN, {
    method: 'post',
    data: { code },
  });
};

/**
 * Obtiene la url para descargar archivo asociados al paso "Firmar Documentación"
 * @param token token para autorizar la descarga
 * @param type tipo del documento
 * @returns
 */
export const getSignDocumentPreview = (
  token: string,
  type: SignDocumentType,
): string => {
  return `${baseURL}${TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW}?token=${token}&type=${type}`;
};

export type SignDocumentType =
  | 'contract'
  | 'promissory-note'
  | 'insurance'
  | 'lem'
  | 'nem'
  | 'postulant-passport'
  | 'sustainer-passport'
  | 'cosigner-passport';

/**
 * Obtiene el contrato que debe firmar el postulante
 * @param token
 * @returns
 */
export const getPreviewDocument = (token: string): string => {
  return `${baseURL}${TUITION_PROCESS.SIGN_DOCUMENTATION_PREVIEW}?token=${token}`;
};

/**********************
 * ARANCEL Y CUOTAS
 *********************/
export const getTariffFormData = () => {
  return request<TariffFormData>(TUITION_PROCESS.TARIFF_QUOTAS_FORM_DATA);
};

export const getTariffInfo = () => {
  return request<TariffData>(TUITION_PROCESS.TARIFF_QUOTAS);
};
export const getTariffQuotasSimulation = (data: {
  tariff: number;
  tariffDownPayment: number;
  matriculation: number;
  numberOfInstalment: number;
  payday: Payday;
  tariffPaymentType: TariffPaymentTypeCodesEnum;
  tariffPaymentMethod: string;
}) => {
  return request<any>(TUITION_PROCESS.TARIFF_QUOTAS_SIMULATION, {
    method: 'POST',
    data,
  });
};

export const saveTariffInfo = (params: {
  quotaId: number;
  paymentMethodCode?: string;
  tariffPaymentTypeCode?: string;
  documentTypeCode?: string;
  businessActivity?: string;
  economicActivityCode?: string | number;
  businessCity?: number;
}) => {
  return requestAuth<any>(TUITION_PROCESS.TARIFF_QUOTAS_SAVE, {
    method: 'POST',
    data: {
      quotaId: params.quotaId,
      paymentMethodCode: params.paymentMethodCode,
      tariffPaymentTypeCode: params.tariffPaymentTypeCode,
      documentTypeCode: params.documentTypeCode,
      businessActivity: params.economicActivityCode ?? params.businessActivity,
      businessCity: params.businessCity,
    },
  });
};
