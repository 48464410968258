import { Dialog, ToastProvider } from '@octano/global-ui';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import AppVersion from './components/version/AppVersion';
import routes from './config/routes';
import { useUserState } from './hooks/useUserState';
import ParametersLoaderProvider from './providers/ParametersLoaderProvider';
import ParametersProvider from './providers/ParametersProvider';
import ParametersUnauthenticatedLoaderProvider from './providers/ParametersUnauthenticatedLoaderProvider';

function App() {
  const { isLogged } = useUserState();
  const ParametersLoader = isLogged
    ? ParametersLoaderProvider
    : ParametersUnauthenticatedLoaderProvider;

  return (
    <ToastProvider>
      <ParametersProvider>
        <ParametersLoader>
          <AppVersion />
          <Router>
            <Switch>
              {Object.entries(routes)?.map(([key, value]) => {
                const Layout = value.layoutComponent;
                return (
                  <Route
                    key={key}
                    path={key}
                    render={(props: any) => <Layout {...props} />}
                  />
                );
              })}
              <Redirect from="*" to="/auth" />
            </Switch>
          </Router>
          <Dialog />
        </ParametersLoader>
      </ParametersProvider>
    </ToastProvider>
  );
}

export default App;
