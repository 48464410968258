import { ReactElement, useCallback } from 'react';
import { DefaultValues } from 'react-hook-form';

import { getSustainer } from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useParameters } from '../../../../hooks/useParameters';
import { useUserState } from '../../../../hooks/useUserState';
import { createFetchContext } from '../../../../providers/FetchContextFactory';
import {
  DataOwnSustainer,
  SustainerStepResponse,
} from '../../../../types/sustainerTypes';
import { FieldsSustainerForm } from './SustainerForm';
import { formatDataOwnSustainer, formatDefaultValues } from './utils';

export interface SustainerLoaderProps {
  children: (props: {
    sustainerPassportAvailable: boolean;
    defaultValues: DefaultValues<FieldsSustainerForm>;
    ownSustainer: DataOwnSustainer;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  SustainerStepResponse
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function SustainerLoader({ children }: SustainerLoaderProps) {
  const {
    communeOptions,
    regionOptions,
    countryOptions,
    nationalityOptions,
    cityOptions,
  } = useParameters();

  const { setIsSessionExpired } = useUserState();

  const request = useCallback(async () => {
    return getSustainer();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 401) {
              setIsSessionExpired(true);
            }
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            sustainerPassportAvailable:
              data.sustainerPassportAvailable ?? false,
            defaultValues: formatDefaultValues(
              data,
              communeOptions,
              regionOptions,
              countryOptions,
              nationalityOptions,
            ),
            ownSustainer: formatDataOwnSustainer({
              data,
              communeOptions,
              regionOptions,
              countryOptions,
              nationalityOptions,
              cityOptions,
            }),
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
