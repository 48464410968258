import { Steps } from '@octano/global-ui';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import ChangeStudyPlan from '../../components/tuitionProcess/ChangeStudyPlan';
import { useStepState } from '../../hooks/useStepState';
import { useTuitionProcessNoSuaSteps } from '../../hooks/useTuitionProcessNoSuaSteps';
import { useUserState } from '../../hooks/useUserState';
import StepProvider from '../../providers/StepProvider';
import withProvider from '../../providers/withProvider';
import { PostulationDetailType } from '../../types/userTypes';

/**
 * Vista del proceso de matrícula del proceso SIN SUA
 */
interface TuitionProcessNoSuaProps {
  postulationDetail: PostulationDetailType | null;
  student: {
    fullName?: string;
    rut?: string;
    postulantId?: number;
  };
  stepId?: string;
  hasOnlinePayment?: boolean;
  refresh: () => Promise<void>;
}

const TuitionProcessNoSua = (props: TuitionProcessNoSuaProps) => {
  const {
    postulationDetail,
    student,
    stepId,
    hasOnlinePayment = false,
  } = props;
  const {
    setUserFullName,
    setUserRut,
    setStatusPostulation,
    setPostulationDetail,
    setPostulantId,
  } = useUserState();

  const {
    namesSteps,
    CurrentStepComponent,
    getIndexStep,
  } = useTuitionProcessNoSuaSteps({ hasOnlinePayment });

  const { currentStep, setCurrentStep } = useStepState();

  useEffect(() => {
    if (postulationDetail) {
      setUserFullName(student.fullName!);
      setUserRut(student.rut!);
      setStatusPostulation(postulationDetail.status ?? '');
      setPostulantId(student.postulantId!);
      setPostulationDetail(postulationDetail);
      if (stepId) {
        setCurrentStep(getIndexStep(stepId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setPostulantId,
    setPostulationDetail,
    setStatusPostulation,
    setUserFullName,
    setUserRut,
  ]);

  return (
    <Row>
      {/* Pasos del proceso */}
      <Col xs={12} className="pb-4">
        <Steps steps={namesSteps} currentStep={currentStep} />
      </Col>
      <Col xs={12} className="pt-2">
        {CurrentStepComponent && (
          <CurrentStepComponent
            children={
              <ChangeStudyPlan refresh={props.refresh} isVisible={true} />
            }
          />
        )}
      </Col>
    </Row>
  );
};

export default withProvider<TuitionProcessNoSuaProps>(
  TuitionProcessNoSua,
  StepProvider,
);
