// Hooks
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { GatewayPaymentLinkResponse } from '../types';

// Types
export type GatewayRedirectMethods = {
  redirect: (params?: GatewayPaymentLinkResponse) => void;
};

type GatewayRedirectProps = {};

// Render
const GatewayRedirect = (
  _props: GatewayRedirectProps,
  ref: Ref<GatewayRedirectMethods>,
) => {
  const formRef: any = useRef<HTMLFormElement>(null);

  const [params, setParams] = useState<GatewayPaymentLinkResponse>();

  const handleRedirect = useCallback((_params?: GatewayPaymentLinkResponse) => {
    setParams(_params);
  }, []);

  useImperativeHandle(ref, () => ({
    redirect: handleRedirect,
  }));

  useEffect(() => {
    if (params?.action?.trim()) {
      formRef?.current?.submit();
    }
  }, [params?.action, formRef]);

  return (
    <form ref={formRef} method={params?.method} action={params?.action}>
      {params?.inputs?.map((e, i) => (
        <input key={i?.toString()} type="hidden" {...e} />
      ))}
    </form>
  );
};

export default forwardRef(GatewayRedirect);
