import { Card, Col, Row } from 'reactstrap';

import BaseLoader from './parts/BaseLoader';
import Payment from './parts/Payment';

const PaymentStep = () => {
  return (
    <BaseLoader>
      {({ data }) => (
        <Card className="px-4 px-lg-5 py-4">
          <Row>
            <Col xs={12} className="pt-lg-4 pb-lg-5">
              <Payment {...data} />
            </Col>
          </Row>
        </Card>
      )}
    </BaseLoader>
  );
};

export default PaymentStep;
