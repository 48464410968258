import TuitionProcessNoSua from './TuitionProcessNoSua';
import TuitionProcessStatusLoader from './steps/StudentFile/parts/TuitionProcessStatusLoader';

function index() {
  return (
    <TuitionProcessStatusLoader>
      {({ postulationDetail, student, refresh, stepId, hasOnlinePayment }) => {
        return (
          <TuitionProcessNoSua
            postulationDetail={postulationDetail}
            student={student}
            hasOnlinePayment={hasOnlinePayment}
            stepId={stepId}
            refresh={refresh}
          />
        );
      }}
    </TuitionProcessStatusLoader>
  );
}

export default index;
