import { Button, Icon } from '@octano/global-ui';
import { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { Col, FormText, Row } from 'reactstrap';

import styles from './FileInput.module.scss';

type FileInputProps = {
  name: string;
  label?: string;
  accept?: string;
  rules?: RegisterOptions;
  onTrash?: () => void;
};

/**
 * Basado en código de https://github.com/react-hook-form/react-hook-form/discussions/2146#discussioncomment-579355
 */
const FileInput = (props: FileInputProps) => {
  const { name, label = name, rules, onTrash } = props;
  const { control } = useFormContext();

  return (
    <>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState }) => (
          <Dropzone
            accept={props.accept}
            multiple={false}
            onDrop={field.onChange}
          >
            {({ getInputProps, open }) => (
              <Fragment>
                <Row>
                  <Col xs={12} sm={6} className="pb-2">
                    {field.value?.length ? (
                      <div className={styles.fileContainer}>
                        {field.value.map((file: any, index: number) => {
                          return (
                            <Fragment key={index}>
                              <span>
                                <Icon name="file_img" size="25px" />
                              </span>
                              <span className={styles.fileName}>
                                {file.name}
                              </span>
                              {Boolean(onTrash) && (
                                <span role="button" onClick={onTrash}>
                                  <Icon name="trash" size="16px" />
                                </span>
                              )}
                            </Fragment>
                          );
                        })}
                      </div>
                    ) : (
                      <div className={styles.fileContainer} />
                    )}
                  </Col>
                  <Col xs={12} sm={6} className="pb-2">
                    <Button
                      text="Cargar imagen"
                      color="primary"
                      outlined
                      size="md"
                      onClick={open}
                      icon="export"
                      fullwidth
                    />
                  </Col>
                </Row>
                <input name={field.name} {...getInputProps()} />
                <FormText className="g-input-error" color="danger">
                  {fieldState.error?.message}
                </FormText>
              </Fragment>
            )}
          </Dropzone>
        )}
      />
    </>
  );
};

export default FileInput;
