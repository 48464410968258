import { ReactElement, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';

import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import { createFetchContext } from '../../../../../providers/FetchContextFactory';
import { QuotaExceed } from '../../shared/QuotaExceed';
import {
  GetPaymentResultData,
  GetPaymentResultError,
  getPaymentData,
} from '../requests';
import { GatewayPaymentResponse } from '../types';

export interface BaseLoaderProps {
  children: (props: {
    data: GatewayPaymentResponse;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  GetPaymentResultData,
  GetPaymentResultError
>();

export const BaseLoaderConsumer = FetchConsumer;

/**
 *
 * Base Loader debe contener toda la data necesaria para que el formulario funcione.
 * opciones de select, settings, etc.
 */
export default function BaseLoader({ children }: BaseLoaderProps) {
  const { postulationDetailId } = useParams<{
    postulationDetailId: string;
  }>();

  const request = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('TBK_TOKEN') ?? urlParams?.get('token_ws');
    window.history.replaceState(
      {},
      document.title,
      `/tuition-process/steps/${postulationDetailId}`,
    );

    return getPaymentData(token ?? undefined);
  }, [postulationDetailId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'QUOTA_EXCEEDED') {
              return (
                <Card className="p-4">
                  <QuotaExceed />
                </Card>
              );
            } else {
              return (
                <DisplayError
                  insideCard
                  textBody={error.code}
                  retryAction={refresh}
                  loadingAction={loading}
                />
              );
            }
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />;
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            data,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
