import { ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { AxiosResultDefaultError } from '../../../api/request';
import { getAllTuitionProcess } from '../../../api/requests/multiTuitionProcess';
import { AuthenticationError } from '../../../api/requests/tuitionProcess';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useUserState } from '../../../hooks/useUserState';
import { createFetchContext } from '../../../providers/FetchContextFactory';
import { POSTULATION_STATUS } from '../../../types/userTypes';
import {
  isMultiTuition,
  studentDataAdapter,
} from '../helper/studentDataAdapter';
import { SearchPostulant, Student } from '../interfaces/online';

export interface TuitionsEnrollmentLoaderProps {
  children: (props: {
    currentPostulations: Student[] | Student | null;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  SearchPostulant,
  AuthenticationError | AxiosResultDefaultError
>();

export const TuitionsEnrollmentLoaderConsumer = FetchConsumer;

export const useTuitionProcessStatus = useFetch;

export default function TuitionsEnrollmentLoader({
  children,
}: TuitionsEnrollmentLoaderProps) {
  const request = useCallback(async () => {
    return getAllTuitionProcess();
  }, []);

  const { setIsSessionExpired } = useUserState();
  const history = useHistory();

  /**
   * Al obtener la data se validara si es un array o no. Si es array es porque esta
   * ON el modo multiTuition y sino es porque esta OFF el modo multiTuition.
   * If isMultiTuition is true them se actualizara el estado como un array de student,
   * else them se actualizara el estado como un objeto de student
   *
   * @param data SearchPostulant[] | SearchPostulant
   * @param values Values
   */
  const formatStudentPending = useCallback(
    (data: SearchPostulant[] | SearchPostulant) => {
      if (isMultiTuition(data)) {
        const students = data.map((student) => {
          return studentDataAdapter(student);
        });
        return students;
      } else {
        const student = studentDataAdapter(data);
        return student;
      }
    },
    [],
  );

  const havePostulationInProcess = useCallback(
    (data: SearchPostulant[] | SearchPostulant) => {
      const currentPostulations = formatStudentPending(data);
      const currentPostulationsArr = Array.isArray(currentPostulations)
        ? currentPostulations
        : [currentPostulations];
      return currentPostulationsArr?.find(
        (element) =>
          element.status === POSTULATION_STATUS.IN_PROCESS ||
          element.status === POSTULATION_STATUS.DESISTED ||
          element.status === POSTULATION_STATUS.IN_WAITING_LIST,
      );
    },
    [formatStudentPending],
  );

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 401) {
              setIsSessionExpired(true);
            }
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (havePostulationInProcess(data)) {
            history.replace(
              `/tuition-process/steps/${
                havePostulationInProcess(data)?.Details_id ?? undefined
              }`,
            );
            return;
          }

          return children({
            currentPostulations: formatStudentPending(data),
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
