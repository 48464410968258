import { FC, createContext, useContext } from 'react';

export enum TuitionProcessContextErrorCode {
  Connection = 'CONNECTION',
  Authentication = 'AUTHENTICATION',
}
export interface TuitionProcessContextState {
  prefix: string;
  postulationDetailId?: number | string;
  onError: (
    code: TuitionProcessContextErrorCode | string,
    onUnknown?: () => void,
  ) => void;
  onCancelFirstStep?: () => void;
}

const contextDefaultValues: TuitionProcessContextState = {
  prefix: '',
  postulationDetailId: 0,
  onError: () => {
    throw new Error(
      'You must implement a TuitionProcessProvider onError handler',
    );
  },
};

export const TuitionProcessContext = createContext<TuitionProcessContextState>(
  contextDefaultValues,
);

export function useTuitionProcess() {
  return useContext(TuitionProcessContext);
}

export type TuitionProcessProviderProps = TuitionProcessContextState;

const TuitionProcessProvider: FC<TuitionProcessProviderProps> = ({
  children,
  ...props
}) => {
  return (
    <TuitionProcessContext.Provider value={props}>
      {children}
    </TuitionProcessContext.Provider>
  );
};

export default TuitionProcessProvider;
