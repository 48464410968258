import { AuthContainer as Container, useWindowSize } from '@octano/global-ui';
import { ReactNode } from 'react';

import { ReactComponent as AddemicLoginImage } from '../../assets/addemic/login_image.svg';
import { ReactComponent as AddemicProductLogo } from '../../assets/addemic/logo_expanded.svg';
import { ReactComponent as AddemicWhiteProductLogo } from '../../assets/addemic/logo_expanded_white.svg';
import { ReactComponent as CampusProductLogo } from '../../assets/campus/logo_expanded.svg';
import { ReactComponent as CampusWhiteProductLogo } from '../../assets/campus/logo_expanded_white.svg';
import { IS_ADDEMIC } from '../../config/constants';

const MAX_WIDTH_WITHOUT_ILLUSTRATION = 992;

export default function AuthContainer({ children }: { children: ReactNode }) {
  const { width: windowWidth } = useWindowSize();

  const hideBottomLogo =
    IS_ADDEMIC && (windowWidth || Infinity) <= MAX_WIDTH_WITHOUT_ILLUSTRATION;

  return (
    <Container
      brandLogoWhite={
        IS_ADDEMIC ? <AddemicWhiteProductLogo /> : <CampusWhiteProductLogo />
      }
      loginImage={
        IS_ADDEMIC ? (
          <AddemicLoginImage style={{ marginTop: -40 }} />
        ) : (
          <img src="/tenant/login_image.svg" alt="tenant_login_image" />
        )
      }
      brandLogoColor={
        hideBottomLogo ? (
          <></>
        ) : (
          <>{IS_ADDEMIC ? <AddemicProductLogo /> : <CampusProductLogo />}</>
        )
      }
    >
      {children}
    </Container>
  );
}
