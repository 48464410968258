import { StepAction, StepActionType, StepState } from '../types/stepsTypes';

export const initialState: StepState = {
  currentStep: 0,
};

const stepReducer = (state: StepState, action: StepAction): StepState => {
  switch (action.type) {
    case StepActionType.PREV_STEP:
      return {
        ...initialState,
        currentStep: state.currentStep - 1,
      };

    case StepActionType.NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };

    case StepActionType.SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };

    default:
      return state;
  }
};

export default stepReducer;
