import { Dispatch } from 'react';

export enum StepActionType {
  NEXT_STEP = 'NEXT_STEP',
  PREV_STEP = 'PREV_STEP',
  SET_STEP = 'SET_STEP',
}

export type StepAction =
  | { type: StepActionType.NEXT_STEP }
  | { type: StepActionType.PREV_STEP }
  | { type: StepActionType.SET_STEP; payload: number };

export type StepState = {
  currentStep: number;
};

export type StepContextType = {
  state: StepState;
  dispatch: Dispatch<StepAction>;
};
