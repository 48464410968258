import { Modal } from '@octano/global-ui';

import { QuotaExceed } from './QuotaExceed';

interface QuotaExceedModalProps {
  show: boolean;
  toggleModal: () => void;
}

export const QuotaExceedModal = ({
  show,
  toggleModal,
}: QuotaExceedModalProps) => {
  return (
    <Modal
      isOpen={show}
      closeOnBackdrop={false}
      toggle={toggleModal}
      size="md"
      unmountOnClose
    >
      <QuotaExceed />
    </Modal>
  );
};
