import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { useStepState } from '../../hooks/useStepState';

const StepBackButton = () => {
  const { t } = useTranslation();
  const { prevStep } = useStepState();
  return (
    <Button
      icon="back"
      onClick={prevStep}
      outlined
      rounded
      size="sm"
      text={t(`common.btn.goBackStep`)}
    />
  );
};

export default StepBackButton;
