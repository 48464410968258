import { ReactNode } from 'react';
import { Card, Col, Row } from 'reactstrap';

import HeaderStudentFullName from '../../../../components/tuitionProcess/HeaderStudentFullName';
import StepBackButton from '../../../../components/tuitionProcess/StepBackButton';
import BaseLoader from './parts/BaseLoader';
import TariffForm from './parts/TariffForm';
import TariffLoader from './parts/TariffLoader';

export default function Tariff({ children }: { children?: ReactNode }) {
  const prefix = 'tuitionProcessNoSua.tariffQuotas';
  return (
    <BaseLoader>
      {({ selectOptions, settings }) => (
        <TariffLoader options={selectOptions}>
          {({ tariffData, defaultValues, refresh }) => (
            <Card className="px-4 px-lg-5 pt-5">
              <Row className="pb-4 pb-md-0">
                <Col xs={12}>
                  <StepBackButton />
                </Col>
                <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
                  <HeaderStudentFullName prefix={prefix} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="pt-lg-4 pb-lg-4">
                  <TariffForm
                    {...{
                      selectOptions,
                      settings,
                      defaultValues,
                      tariffData,
                      refresh,
                    }}
                  />
                  {children}
                </Col>
              </Row>
            </Card>
          )}
        </TariffLoader>
      )}
    </BaseLoader>
  );
}
