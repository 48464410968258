import { Select, TextInput } from '@octano/global-ui';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useParameters } from '../../../hooks/useParameters';
import { useValidations } from '../../../hooks/useValidations';
import {
  RELATIONSHIP_OWN_SUSTAINER,
  SustainerType,
  relationshipOptions,
  sustainerTypeOptions,
  sustainerTypeWithPassportOptions,
} from '../../../utils/selectOptions';
import { useTuitionProcess } from '../../../views/TuitionProcess/TuitionProcessContext';
import { SectionTitle } from '../../text';
import useClearInputs from '../hooks/useClearInputs';
import { DynamicContactType } from './RenderSections';

export default function DynamicInformation({
  type,
  sustainerPassportAvailable,
  postulantHasPassport,
  fieldNames = {
    businessName: 'businessName',
    businessRut: 'businessRut',
    relationship: 'relationship',
    occupation: 'occupation',
    names: 'names',
    paternalLastName: 'paternalLastName',
    maternalLastName: 'maternalLastName',
    rut: 'rut',
    passportCountry: 'passportCountry',
    passportNumber: 'passportNumber',
    hasPassport: 'hasPassport',
    sustainerType: 'sustainerType',
  },
}: {
  type: DynamicContactType;
  sustainerPassportAvailable: boolean;
  postulantHasPassport: boolean;
  fieldNames?: {
    businessName: string;
    businessRut: string;
    relationship: string;
    occupation: string;
    names: string;
    paternalLastName: string;
    maternalLastName: string;
    rut: string;
    passportCountry: string;
    passportNumber: string;
    hasPassport: string;
    sustainerType: string;
  };
}) {
  const { t } = useTranslation();
  const { countryOptions } = useParameters();
  const { prefix: translationPrefix } = useTuitionProcess();
  const { control, watch, setValue } = useFormContext<any>();
  const {
    msgValidations,
    validateRut,
    validateTextNotEmpty,
  } = useValidations();
  const prefix = `${translationPrefix}.sustainer.personalInformation`;

  const hasPassport = watch('hasPassport');
  const sustainer = watch('sustainerType');
  const ownSustainer = watch('ownSustainer');

  const handleChangeOwnSutainer = useCallback(
    (next: boolean) => {
      if (!next) {
        setValue(fieldNames?.businessName, '');
        setValue(fieldNames?.businessRut, '');
        setValue(fieldNames?.relationship, null);
        setValue(fieldNames?.occupation, '');
        setValue(fieldNames?.names, '');
        setValue(fieldNames?.paternalLastName, '');
        setValue(fieldNames?.maternalLastName, '');
        setValue(fieldNames?.rut, '');
        setValue(fieldNames?.passportCountry, null);
        setValue(fieldNames?.passportNumber, '');
        if (
          ![
            SustainerType.NaturalPersonWithPassport,
            SustainerType.OwnSustainer,
          ].includes(sustainer?.value)
        ) {
          setValue(fieldNames?.hasPassport, '');
        }
      }
    },
    [
      fieldNames?.businessName,
      fieldNames?.hasPassport,
      fieldNames?.maternalLastName,
      fieldNames?.names,
      fieldNames?.occupation,
      fieldNames?.passportCountry,
      fieldNames?.passportNumber,
      fieldNames?.paternalLastName,
      fieldNames?.relationship,
      fieldNames?.rut,
      setValue,
    ],
  );

  useClearInputs({ type, handleChangeOwnSutainer });

  useEffect(() => {
    if (sustainer?.value === SustainerType.OwnSustainer) {
      setValue('hasOptionalValues', true);
      setValue('ownSustainer', true);
    } else if (sustainer?.value === SustainerType.NaturalPersonWithPassport) {
      setValue('hasOptionalValues', false);
      setValue('ownSustainer', false);
      setValue('rut', undefined);
      setValue('hasPassport', true);
    } else {
      setValue('hasOptionalValues', false);
      setValue('ownSustainer', false);
      setValue('rut', undefined);
    }
  }, [setValue, sustainer?.value]);

  useEffect(() => {
    if (hasPassport) {
      setValue('rut', undefined);
    }
  }, [hasPassport, setValue]);

  const filteredRelationshipOptions = useMemo(
    () =>
      relationshipOptions.filter(
        (row) =>
          (ownSustainer && row.value === RELATIONSHIP_OWN_SUSTAINER) ||
          row.value !== RELATIONSHIP_OWN_SUSTAINER,
      ),
    [ownSustainer],
  );

  console.log(
    '🚀 ~ requestPassport ~ postulantHasPassport:',
    ownSustainer,
    postulantHasPassport,
    hasPassport,
  );
  const requestPassport = useMemo(() => {
    if (!sustainerPassportAvailable) {
      return false;
    }
    return ownSustainer ? postulantHasPassport : hasPassport;
  }, [
    sustainerPassportAvailable,
    ownSustainer,
    postulantHasPassport,
    hasPassport,
  ]);

  const fields = useMemo(() => {
    if (sustainer?.value === SustainerType.LegalPerson)
      return (
        <Fragment key="legalPerson">
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames?.businessName}
              label="Razón social"
              control={control}
              disabled={ownSustainer}
              rules={{ required: msgValidations.required }}
              shouldUnregister={true}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames?.businessRut}
              label={t(`${prefix}.${fieldNames?.rut}`)}
              control={control}
              formatter="rut"
              disabled={ownSustainer}
              shouldUnregister={true}
              rules={{
                validate: {
                  required: validateTextNotEmpty,
                  rut: validateRut,
                },
              }}
            />
          </Col>
        </Fragment>
      );

    return (
      <Fragment key="naturaPerson">
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames?.relationship}
            label={t(`${prefix}.${fieldNames?.relationship}`)}
            options={filteredRelationshipOptions}
            isSearchable={false}
            control={control}
            disabled={Boolean(ownSustainer)}
            shouldUnregister={true}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames?.occupation}
            label={t(`${prefix}.${fieldNames?.occupation}`)}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames?.names}
            label={t(`${prefix}.${fieldNames?.names}`)}
            control={control}
            readOnly={Boolean(ownSustainer)}
            shouldUnregister={true}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames?.paternalLastName}
            label={t(`${prefix}.${fieldNames?.paternalLastName}`)}
            control={control}
            readOnly={Boolean(ownSustainer)}
            shouldUnregister={true}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames?.maternalLastName}
            label={t(`${prefix}.${fieldNames?.maternalLastName}`)}
            control={control}
            readOnly={Boolean(ownSustainer)}
            shouldUnregister={true}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Row>
            {[
              SustainerType.NaturalPersonWithPassport,
              SustainerType.OwnSustainer,
            ].includes(sustainer?.value) && requestPassport ? (
              <>
                <Col xs={12} md={6} className="pr-md-1">
                  <Select
                    name={fieldNames?.passportCountry}
                    label={t(`${prefix}.${fieldNames?.passportCountry}`)}
                    control={control}
                    options={countryOptions}
                    shouldUnregister={true}
                    isClearable={false}
                    disabled={Boolean(ownSustainer)}
                  />
                </Col>
                <Col xs={12} md={6} className="pl-md-1">
                  <TextInput
                    name={fieldNames?.passportNumber}
                    label={t(`${prefix}.${fieldNames?.passportNumber}`)}
                    control={control}
                    shouldUnregister={true}
                    readOnly={Boolean(ownSustainer)}
                  />
                </Col>
              </>
            ) : (
              <Col xs={12}>
                <TextInput
                  name={fieldNames?.rut}
                  label={t(`${prefix}.${fieldNames?.rut}`)}
                  control={control}
                  formatter="rut"
                  shouldUnregister={true}
                  readOnly={Boolean(ownSustainer)}
                  rules={{
                    validate: {
                      required: validateTextNotEmpty,
                      rut: validateRut,
                    },
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Fragment>
    );
  }, [
    sustainer?.value,
    fieldNames?.businessName,
    fieldNames?.businessRut,
    fieldNames?.rut,
    fieldNames?.relationship,
    fieldNames?.occupation,
    fieldNames?.names,
    fieldNames?.paternalLastName,
    fieldNames?.maternalLastName,
    fieldNames?.passportCountry,
    fieldNames?.passportNumber,
    control,
    ownSustainer,
    msgValidations.required,
    t,
    prefix,
    validateTextNotEmpty,
    validateRut,
    filteredRelationshipOptions,
    requestPassport,
    countryOptions,
  ]);

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} md={4}>
          <Select
            name="sustainerType"
            label={t(`${prefix}.sustainerType`)}
            options={
              sustainerPassportAvailable
                ? sustainerTypeWithPassportOptions
                : sustainerTypeOptions
            }
            isSearchable={false}
            control={control}
          />
        </Col>
        {fields}
      </Row>
    </Fragment>
  );
}
