import { StudentOnline } from './online/StudentOnline';
import RefreshTokenLoader from './parts/RefreshTokenLoader';
import TuitionsEnrollmentLoader from './parts/TuitionProcessStatusLoader';

export const OnlineEnrollment = () => {
  return (
    <RefreshTokenLoader>
      {() => (
        <>
          <TuitionsEnrollmentLoader>
            {({ currentPostulations, refresh }) => {
              return (
                <StudentOnline
                  currentPostulations={currentPostulations}
                  refresh={refresh}
                />
              );
            }}
          </TuitionsEnrollmentLoader>
        </>
      )}
    </RefreshTokenLoader>
  );
};
