import { Select, TextInput } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useFilteredCommunes } from '../../../hooks/useFilteredCommunes';
import { useParameters } from '../../../hooks/useParameters';
import { useValidations } from '../../../hooks/useValidations';
import { SectionTitle } from '../../text';
import useClearInputs from '../hooks/useClearInputs';
import { DynamicContactType } from './RenderSections';

const CHILE_LOWER_CASE = 'chile';

type ContactSectionProps = {
  prefix: string;
  type: DynamicContactType;
  optionalFields?: string[];
  fieldNames?: {
    addressStreet: string;
    addressNumber: string;
    addressExtra: string;
    country: string;
    region: string;
    commune: string;
    phone: string;
    cellPhone: string;
    email: string;
    location: string;
  };
  disabled?: boolean;
  hasEmail?: boolean;
};

/**
 * Para usar este componente tu formulario debe estar envuelto en su FormProvider
 * para poder acceder a la información del formulario a través de useFormContext
 * Los nombres agregados en fieldNames se relacionan a los campos que utilizaste
 * al definir tu formulario.
 */
const DynamicContactCampus = ({
  prefix,
  type,
  optionalFields = [],
  fieldNames = {
    addressStreet: 'addressStreet',
    addressNumber: 'addressNumber',
    addressExtra: 'addressExtra',
    country: 'country',
    region: 'region',
    commune: 'commune',
    phone: 'phone',
    cellPhone: 'cellPhone',
    email: 'email',
    location: 'location',
  },
  disabled = false,
  hasEmail = false,
}: ContactSectionProps) => {
  const { t } = useTranslation();
  const { control, watch, formState, setValue } = useFormContext();
  const {
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const { regionOptions, countryOptions } = useParameters();

  const ownSustainer = watch('ownSustainer');
  const selectedRegion = watch(fieldNames.region);
  const rut = watch('rut');
  const filteredCommunes = useFilteredCommunes(selectedRegion);

  const isRegionTouched = formState.touchedFields[fieldNames.region];

  const optional = (value: string, fieldName: string) => {
    if (optionalFields.includes(fieldName)) {
      return undefined;
    }
    return validateTextNotEmpty(value);
  };

  const handleChangeOwnSutainer = useCallback(
    (next: boolean) => {
      if (!next) {
        setValue(fieldNames?.addressStreet, '');
        setValue(fieldNames?.addressNumber, '');
        setValue(fieldNames?.addressExtra, '');
        setValue(fieldNames?.region, null);
        setValue(fieldNames?.commune, null);
        setValue(fieldNames?.phone, '');
        setValue(fieldNames?.cellPhone, '');
        setValue(fieldNames?.email, '');
        setValue(fieldNames?.location, '');
      }
    },
    [
      fieldNames?.addressExtra,
      fieldNames?.addressNumber,
      fieldNames?.addressStreet,
      fieldNames?.cellPhone,
      fieldNames?.commune,
      fieldNames?.email,
      fieldNames?.location,
      fieldNames?.phone,
      fieldNames?.region,
      setValue,
    ],
  );

  useClearInputs({ type, handleChangeOwnSutainer });

  useEffect(() => {
    if (rut) {
      setValue(
        fieldNames.country,
        countryOptions.find(
          (c) => c.label.toLocaleLowerCase() === CHILE_LOWER_CASE,
        ),
      );
    }
  }, [countryOptions, fieldNames.country, rut, setValue]);

  useEffect(() => {
    if (isRegionTouched) {
      setValue(fieldNames.commune, null);
    }
  }, [selectedRegion, setValue, isRegionTouched, fieldNames.commune]);

  return (
    <>
      <Row className="pt-5">
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={8}>
          <TextInput
            name={fieldNames.addressStreet}
            label={t(`${prefix}.${fieldNames.addressStreet}`)}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.addressStreet),
              },
            }}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.addressNumber}
            label={t(`${prefix}.${fieldNames.addressNumber}`)}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.addressNumber),
              },
            }}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.addressExtra}
            label={t(`${prefix}.${fieldNames.addressExtra}`)}
            control={control}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames.region}
            label={t(`${prefix}.${fieldNames.region}`)}
            options={regionOptions}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.region),
              },
            }}
            disabled={disabled || ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames.commune}
            label={t(`${prefix}.${fieldNames.commune}`)}
            options={filteredCommunes}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.commune),
              },
            }}
            shouldUnregister
            disabled={disabled || ownSustainer === true}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.phone}
            label={t(`${prefix}.${fieldNames.phone}`)}
            control={control}
            formatter="phone"
            rules={{ validate: validatePhone }}
            placeholder={t(`common.placeholder.phone`)}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.cellPhone}
            label={t(`${prefix}.${fieldNames.cellPhone}`)}
            control={control}
            formatter="phone"
            rules={{
              validate: {
                phone: validatePhone,
                require: (value) => optional(value, fieldNames.cellPhone),
              },
            }}
            placeholder={t(`common.placeholder.cellphone`)}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        {hasEmail ? (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames.email}
              label={t(`${prefix}.${fieldNames.email}`)}
              control={control}
              disabled={disabled}
              rules={{
                validate: {
                  required: validateTextNotEmpty,
                  email: validateEmail,
                },
              }}
              readOnly={disabled || ownSustainer === true}
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default DynamicContactCampus;
