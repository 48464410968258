import { addToast } from '@octano/global-ui';
import { CardLink, Col, Row } from 'reactstrap';

import { changeToDesisted } from '../../api/requests/tuitionProcess';

interface ChangeStudyPlanProps {
  refresh?: () => Promise<void>;
  isVisible: boolean;
}

function ChangeStudyPlan(props: ChangeStudyPlanProps) {
  const { isVisible, refresh } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <Row>
      <Col xs={12} className="text-right">
        <p className="mb-0 mt-4">
          Si deseas cambiar tu selección de plan de estudio, puedes hacerlo
          <CardLink
            className="pl-1 cursor-pointer"
            onClick={async () => {
              const { data: desistedStatusData } = await changeToDesisted();
              if (desistedStatusData) {
                refresh?.();
                addToast({
                  icon: 'success',
                  color: 'success',
                  text: 'Se ha cambiado el estado de la postulación',
                });
              } else {
                addToast({
                  icon: 'error',
                  color: 'danger',
                  text:
                    'Ha ocurrido un error al cambiar el estado de la postulación',
                });
              }
            }}
          >
            haciendo clic aquí.
          </CardLink>
        </p>
      </Col>
    </Row>
  );
}

export default ChangeStudyPlan;
