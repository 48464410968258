import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { DynamicContactType } from '../parts/RenderSections';

const useClearInputs = ({
  type,
  handleChangeOwnSutainer,
}: {
  type: DynamicContactType;
  handleChangeOwnSutainer: (next: boolean) => void;
}) => {
  const { watch, formState } = useFormContext();
  const ownSustainer = watch('ownSustainer');

  const isSustainerTypeTouched = formState.touchedFields['sustainerType'];

  useEffect(() => {
    if (type === 'sustainerFile' && !ownSustainer && isSustainerTypeTouched) {
      handleChangeOwnSutainer(ownSustainer);
    }
  }, [handleChangeOwnSutainer, isSustainerTypeTouched, ownSustainer, type]);
};

export default useClearInputs;
