/**
 * Endpoints para el proceso de matrícula
 */
const TUITION_PROCESS = {
  TUITION_PROCESS_STEPS: '/postulant-portal/tuition-process-steps',
  //Paso de la ficha del postulante
  TUITION_PROCESS_STEPS_BY_POSTULATION_DETAIL: (
    postulationDetailId?: string | number,
  ) => `/postulant-portal/tuition-process-steps/${postulationDetailId}`,
  //Paso de la ficha del postulante
  STUDENT_FILE: '/postulant-portal/tuition-process-steps/student-file',
  //Paso de la ficha del sustentador
  SUSTAINER: '/postulant-portal/tuition-process-steps/sustainer',
  SUSTAINER_FORM_DATA:
    '/postulant-portal/tuition-process-steps/sustainer/form-data',
  //paso de arancel y cuotas
  TARIFF_QUOTAS: '/postulant-portal/tuition-process-steps/tariff/inf/',
  TARIFF_QUOTAS_SIMULATION:
    '/postulant-portal/tuition-process-steps/tariff/simulation/',
  TARIFF_QUOTAS_SAVE:
    '/postulant-portal/tuition-process-steps/tariff/save-quota/',
  TARIFF_QUOTAS_FORM_DATA:
    '/postulant-portal/tuition-process-steps/tariff/form-data',
  //Paso de firma de contrato
  SIGN_DOCUMENTATION:
    '/postulant-portal/tuition-process-steps/sign-documentation',
  SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION: (uploadMethod: string) =>
    `/postulant-portal/tuition-process-steps/sign-documentation/upload-documentation/${uploadMethod}`,
  // @deprecated
  SIGN_DOCUMENTATION_SIGN:
    '/postulant-portal/tuition-process-steps/sign-documentation/sign',
  // @deprecated
  SIGN_DOCUMENTATION_IDENTITY_CARD_UPLOAD:
    '/postulant-portal/tuition-process-steps/sign-documentation/identity-card',
  SIGN_DOCUMENTATION_VERIFY:
    '/postulant-portal/tuition-process-steps/sign-documentation',
  SIGN_DOCUMENTATION_PREVIEW_TOKEN:
    '/postulant-portal/tuition-process-steps/sign-documentation/preview-document/token',
  SIGN_DOCUMENTATION_PREVIEW:
    '/postulant-portal/tuition-process-steps/sign-documentation/download',
  //Paso de pago con webpay
  POSTULANT_PAYMENT_STEP:
    '/postulant-portal/tuition-process-steps/postulant/payment',
  POSTULANT_PAYMENT_VOUCHER:
    '/postulant-portal/tuition-process-steps/postulant/payment/download-voucher',
  POSTULANT_PAYMENT_VOUCHER_TOKEN:
    '/postulant-portal/tuition-process-steps/postulant/payment/download-voucher/token',
  POSTULANT_GENERATE_PAYMENT_LINK:
    '/postulant-portal/tuition-process-steps/postulant/payment',
  POSTULANT_FINISH_PAYMENT_PROCESS:
    '/postulant-portal/tuition-process-steps/postulant/payment',
  LOGIN: '/postulant-portal/authentications/login',
  REFRESH_TOKEN: '/postulant-portal/authentications/refresh-token',
  POSTULANT_SEARCH: '/postulant-portal/authentications/search-postulant',
  POSTULANT_REGISTRATION: '/postulant-portal/authentications/save-postulant',
  POSTULANT_REGISTRATION_WITH_CAREER:
    '/postulant-portal/authentications/save-postulant-with-career',
  TERMS_AND_CONDITIONS:
    '/postulant-portal/tuition-process-steps/terms-and-conditions',
  TERMS_AND_CONDITIONS_PREVIEW_TOKEN:
    '/postulant-portal/tuition-process-steps/terms-and-conditions/preview/token',
  TERMS_AND_CONDITIONS_PREVIEW:
    '/postulant-portal/tuition-process-steps/terms-and-conditions/preview',
  COSIGNER: '/postulant-portal/tuition-process-steps/cosigner',
  PAYMENT: '/postulant-portal/tuition-process-steps/payment',
  COMPLETED: '/postulant-portal/tuition-process-steps/completed',
  /**
   * POR FAVOR NO SEGUIR AGREGANDO URLS FUERA DEL PORTAL!!!
   */
  ACTIVE_TUITION_PROCESS: '/tuition-processes/active',
};

const POSTULATION = {
  INFORMATION_WAITING_LIST: (admissionId?: number) =>
    `/postulant-portal/postulation/information-waiting-list/${admissionId}`,
  CREATE: '/postulant-portal/postulation/create',
  UPDATE: '/postulant-portal/postulation/update-postulation',
};

const STUDY_PLANS = {
  STUDY_PLAN_QUOTA: '/postulant-portal/study-plans/study-plan-quota',
  STUDY_PLAN_QUOTA_BY_ID: (id: string | number) =>
    `/postulant-portal/study-plans/${id}`,
};

const PARAMETERS = {
  COUNTRIES: '/postulant-portal/places/countries',
  REGIONS_CITIES_COMMUNES: '/postulant-portal/places/all',
  INSTITUTION_TYPES: '/postulant-portal/institution-types',
};

const CERTIFICATES = {
  FIND_ONE: '/certificates-validations',
};

const SETTINGS = {
  TIMEZONE: '/settings/timezone',
};

const DYNAMIC_FORM = {
  STRUCTURE: (id: string | number) => `/dynamic-forms/${id}`,
  GET_FORM_RESPONSES: (
    dynamicFormId: string | number,
    userId: string | number,
  ) => `/dynamic-forms/${dynamicFormId}/user/${userId}/responses`,
  SAVE_FORM_RESPONSES: (
    dynamicFormId: string | number,
    userId: string | number,
  ) => `/dynamic-forms/${dynamicFormId}/user/${userId}/responses`,
};

export {
  TUITION_PROCESS,
  STUDY_PLANS,
  PARAMETERS,
  POSTULATION,
  CERTIFICATES,
  SETTINGS,
  DYNAMIC_FORM,
};
