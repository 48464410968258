import { ReactNode } from 'react';

type ProviderProps = {
  children: ReactNode;
};

const withProvider = <P extends object = any>(
  Component: React.FunctionComponent<P>,
  Provider: React.ComponentType<ProviderProps>,
) => {
  return (props?: P) => (
    <Provider>
      <Component {...(props || ({} as P))} />
    </Provider>
  );
};

export default withProvider;
