import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormText, Row } from 'reactstrap';

import { IdentityTypes } from '../../../../types/signDocumentationTypes';
import IdentityCard from './IdentityCard';

export type IdentityCardProps = {
  identityCards: {
    id: IdentityTypes;
    isUploaded: boolean;
  }[];
  error?: string;
  onUploaded: () => void;
};

/**
 * Listado de CI
 */
const IdentityCards = ({
  identityCards,
  error,
  onUploaded,
}: IdentityCardProps) => {
  const prefix = 'tuitionProcess.sign.identityCard';
  const { t } = useTranslation();
  return (
    <Fragment>
      <Row>
        {identityCards.map((identityCard, index) => (
          <Col xs={12} lg={4} key={index}>
            <IdentityCard
              type={identityCard.id}
              name={t(`${prefix}.${identityCard.id}.name`)}
              isUploaded={identityCard.isUploaded}
              btnUpload={t(`${prefix}.${identityCard.id}.btnUpload`)}
              btnUploaded={t(`${prefix}.${identityCard.id}.btnUploaded`)}
              onUploaded={onUploaded}
            />
          </Col>
        ))}
      </Row>
      <FormText className="g-input-error" color="danger">
        {error || <>&nbsp;</>}
      </FormText>
    </Fragment>
  );
};

export default IdentityCards;
