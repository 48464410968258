import { ReactElement, useCallback } from 'react';
import { DefaultValues } from 'react-hook-form';

import { AxiosResultDefaultError } from '../../../../../api/request';
import {
  AuthenticationError,
  getStudentFile,
} from '../../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import { useParameters } from '../../../../../hooks/useParameters';
import { useUserState } from '../../../../../hooks/useUserState';
import { createFetchContext } from '../../../../../providers/FetchContextFactory';
import {
  AdmissionType,
  StudentFileResponse,
} from '../../../../../types/studentFileSectionTypes';
import WaitingList from '../../WaitingList';
import studenFileFormatPreviusData from '../utils/studentFileFormatPreviusData';
import LoginWithPassport from './LoginWithPassport';

const REGISTRATION_ERROR_MESSAGE = 'passport registration not allowed';

export interface StudentFileLoaderProps {
  onError: (code: string, onUnknown?: (() => void) | undefined) => void;
  children: (props: {
    defaultValues: DefaultValues<any>;
    admissionType: AdmissionType;
    photo: string | null;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  StudentFileResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const StudentFileLoaderConsumer = FetchConsumer;

export default function StudentFileLoader({
  onError,
  children,
}: StudentFileLoaderProps) {
  const request = useCallback(async () => {
    return getStudentFile();
  }, []);

  const { countryOptions } = useParameters();
  const { statusPostulation } = useUserState();
  const { setIsSessionExpired } = useUserState();

  if (statusPostulation === 'IN_WAITING_LIST') {
    return <WaitingList />;
  }

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 401) {
              setIsSessionExpired(true);
            }
            onError(error.code);

            if (error.data.message === REGISTRATION_ERROR_MESSAGE) {
              return (
                <LoginWithPassport
                  phone={error.data.phone}
                  email={error.data.email}
                  academy={error.data.academy}
                />
              );
            }

            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            defaultValues: studenFileFormatPreviusData(data, countryOptions),
            admissionType: data.admissionType,
            photo: data.student.photo,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
