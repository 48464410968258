import { Card } from 'reactstrap';

import { Student } from '../interfaces/online';
import { SearchStudentOnSiteMultiTuition } from './parts/SearchStudentOnSiteMultiTuition';
import { SearchStudentOnSiteSingleTuition } from './parts/SearchStudentOnSiteSingleTuition';

export const StudentOnline = ({
  currentPostulations,
  refresh,
}: {
  currentPostulations: Student[] | Student | null;
  refresh: () => Promise<void>;
}) => {
  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <div style={{ alignSelf: 'center' }}>
          {/* SingleTuition */}
          {currentPostulations && !Array.isArray(currentPostulations) && (
            <SearchStudentOnSiteSingleTuition
              student={currentPostulations}
              preload={refresh}
            />
          )}

          {/* MultiTuition */}
          {currentPostulations && Array.isArray(currentPostulations) && (
            <SearchStudentOnSiteMultiTuition
              students={currentPostulations}
              preload={refresh}
            />
          )}
        </div>
      </Card>
    </>
  );
};
