import AuthLayout from '../layouts/AuthLayout';
import CertificateValidationLayout from '../layouts/CertificateValidationLayout';
import TutionProcessLayout from '../layouts/TutionProcessLayout';
import AuthTuitionProcess from '../views/AuthTuitionProcess/AuthTuitionProcess';
import AuthTuitionProcessNoSua from '../views/AuthTuitionProcessNoSua/AuthTuitionProcessNoSua';
import SearchPostulantProcessNoSua from '../views/AuthTuitionProcessNoSua/SearchPostulantProcessNoSua';
import CertificateDetails from '../views/CertificateValidation/CertificateDetails';
import ValidationForm from '../views/CertificateValidation/ValidationForm';
import { OnlineEnrollment } from '../views/OnlineEnrollment';
import RegistrationNoSua from '../views/RegistrationNoSua';
import TuitionProcess from '../views/TuitionProcess/TuitionProcess';
import TuitionProcessNoSua from '../views/TuitionProcessNoSua';
import { IS_SUA } from './constants';

export enum PathsLayouts {
  auth = '/auth',
  tuitionProcess = '/tuition-process',
  certificateValidation = '/validacioncertificado',
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  // this keys are the paths of every layout
  [key in PathsLayouts]: {
    layoutComponent: () => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
const routes: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: AuthLayout,
    defaultPath: '/tuition-process',
    views: [
      {
        path: '/tuition-process',
        component: IS_SUA ? AuthTuitionProcess : AuthTuitionProcessNoSua,
      },
      {
        path: '/registration/:studyPlanVersionOfferPeriodAdmissionId?',
        component: RegistrationNoSua,
      },
      {
        path: '/search-postulant/:studyPlanVersionOfferPeriodAdmissionId',
        component: SearchPostulantProcessNoSua,
      },
    ],
  },
  [PathsLayouts.tuitionProcess]: {
    layoutComponent: TutionProcessLayout,
    defaultPath: '/',
    views: [
      {
        path: '/',
        component: OnlineEnrollment,
      },
      {
        path: '/steps',
        component: IS_SUA ? TuitionProcess : TuitionProcessNoSua,
      },
      {
        path: '/steps/:postulationDetailId',
        component: IS_SUA ? TuitionProcess : TuitionProcessNoSua,
      },
    ],
  },
  [PathsLayouts.certificateValidation]: {
    layoutComponent: CertificateValidationLayout,
    defaultPath: '/',
    views: [
      {
        path: '/',
        component: ValidationForm,
      },
      {
        path: '/detalle',
        component: CertificateDetails,
      },
    ],
  },
};

export default routes;
