import { Button, Icon } from '@octano/global-ui';
import { Fragment, useState } from 'react';

import IdentityCardModal from './IdentityCardModal';

export type IdentityCardProps = {
  type: string;
  name: string;
  btnUpload: string;
  btnUploaded: string;
  isUploaded: boolean;
  onUploaded: () => void;
};

/**
 * Contenedor de información de documento que debe ser firmado
 * en el paso "Firmar Documentación"
 */
const IdentityCard = ({
  type,
  name,
  btnUpload,
  btnUploaded,
  isUploaded,
  onUploaded,
}: IdentityCardProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <span className="text-uppercase fs-18 fw-600 text-secondary">{name}</span>
      <div className="bg-light px-2 py-3 mt-2">
        <div className="text-center pb-2">
          <Icon name="id" size="79px" color="secondary" />
        </div>
        <div className="px-5 px-md-1">
          <Button
            icon={isUploaded ? 'check' : undefined}
            onClick={() => setOpen(true)}
            size="sm"
            text={isUploaded ? btnUploaded : btnUpload}
            fullwidth
          />
        </div>
      </div>
      <IdentityCardModal
        type={type}
        isOpen={open}
        onClose={(uploaded?: boolean) => {
          setOpen(false);
          if (uploaded) {
            onUploaded();
          }
        }}
      />
    </Fragment>
  );
};

export default IdentityCard;
