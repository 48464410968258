import dayjs from 'dayjs';

import { COMMIT_ID, COMMIT_TIMESTAMP } from '../../config/constants';

const AppVersion = () => {
  if (COMMIT_TIMESTAMP && COMMIT_ID) {
    const commitDate = new Date(COMMIT_TIMESTAMP * 1000);
    return (
      <div className="box-app-version">
        Version: {COMMIT_ID}
        <br />
        Fecha: {dayjs(commitDate).format('DD-MM-YYYY HH:mm')}
      </div>
    );
  } else {
    return null;
  }
};

export default AppVersion;
