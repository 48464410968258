import {
  Button,
  CheckInput,
  Select,
  SelectOptionType,
  TextInput,
  addToast,
} from '@octano/global-ui';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { Card, CardBody, CardSubtitle, Col, Form, Row } from 'reactstrap';
import { clean, format } from 'rut.js';

import { searchPostulant } from '../../api/requests/tuitionProcess';
import { useParameters } from '../../hooks/useParameters';
import { useValidations } from '../../hooks/useValidations';

function SearchPostulantProcessNoSua() {
  //el identificador puede ser rut o pasaporte
  const methods = useForm<{
    rut?: string;
    passportNumber?: string;
    passportCountry?: string;
    hasPassport?: boolean;
  }>();
  const history = useHistory();
  const { studyPlanVersionOfferPeriodAdmissionId } = useParams<{
    studyPlanVersionOfferPeriodAdmissionId: string;
  }>();

  const hasPassport = methods.watch('hasPassport');
  const { countryOptions } = useParameters();
  const {
    validateTextNotEmpty,
    validateRut,
    msgValidations,
  } = useValidations();

  const onSubmit = useCallback(
    async (values: {
      rut?: string;
      passportNumber?: string;
      passportCountry?: SelectOptionType;
      hasPassport?: boolean;
    }) => {
      const { error } = await searchPostulant({
        ...values,
        rut: values.rut ? clean(values.rut) : undefined,
        passportCountry: values.passportCountry?.value as string,
      });

      if (!error) {
        return history.push(
          `/auth/registration/${studyPlanVersionOfferPeriodAdmissionId}`,
          {
            rut: values.rut ? format(values.rut!) : null,
            hasPassport: values.hasPassport,
            passportNumber: values.passportNumber,
            passportCountry: values.passportCountry,
          },
        );
      }
      if (error?.data?.message[0] === 'USER_ALREADY_EXIST') {
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Ya posees una cuenta',
        });
        history.push('/auth/tuition-process', {
          rut: values.rut ? format(values.rut!) : null,
          hasPassport: values.hasPassport,
          passportNumber: values.passportNumber,
          passportCountry: values.passportCountry,
        });
      }
    },
    [history, studyPlanVersionOfferPeriodAdmissionId],
  );

  return (
    <Row
      className="justify-content-center align-items-center"
      style={{ minHeight: '100vh' }}
    >
      <Col xs={12} sm={10} md={6} lg={4}>
        <Card>
          <div className="text-center mt-5">
            <img
              src="/tenant/logo_expanded.svg"
              alt="tenant_logo"
              style={{ maxHeight: 50 }}
            />
          </div>

          <CardBody className=" my-4 px-5">
            <CardSubtitle className="px-5 mb-5 text-center ">
              Para crear tu cuenta debes ingresar la siguiente información:
            </CardSubtitle>
            <div className="px-4">
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                {hasPassport ? (
                  <Row>
                    <Col xs={6} md={6} className="pr-md-1">
                      <Select
                        name="passportCountry"
                        label="País"
                        control={methods.control}
                        options={countryOptions}
                        shouldUnregister={true}
                        isClearable={false}
                        rules={{ required: msgValidations.required }}
                      />
                    </Col>
                    <Col xs={6} md={6} className="pl-md-1">
                      <TextInput
                        name="passportNumber"
                        label="Pasaporte"
                        control={methods.control}
                        shouldUnregister={true}
                        rules={{ validate: validateTextNotEmpty }}
                      />
                    </Col>
                  </Row>
                ) : null}
                {!hasPassport ? (
                  <TextInput
                    formatter="rut"
                    name="rut"
                    control={methods.control}
                    rules={{
                      validate: {
                        required: validateTextNotEmpty,
                        rut: validateRut,
                      },
                    }}
                    label="Rut"
                    shouldUnregister={true}
                  />
                ) : null}

                <CheckInput
                  name="hasPassport"
                  label={'Ingresar Pasaporte'}
                  control={methods.control}
                />

                <Button type="submit" text="Continuar" fullwidth></Button>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default SearchPostulantProcessNoSua;
