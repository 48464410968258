/**
 * Devuelve una matriz de objetos con el nombre del input y la ruta del idioma para el label
 * @param {GeneralTypes} documentName - Todos los tipos de documentos
 * @returns Una matriz de objetos con las propiedades name y labelPath.
 */
export function getInputs(
  uploadMethod: 'signature' | 'identification' | 'default' | 'confirmation',
  commentRequired: boolean,
): {
  name: string;
  labelPath: string;
  type: string;
}[] {
  const inputs = [];
  if (uploadMethod === 'identification') {
    inputs.push(
      {
        name: 'back',
        labelPath: 'postulationDocs.attachment.back',
        type: 'file',
      },
      {
        name: 'front',
        labelPath: 'postulationDocs.attachment.front',
        type: 'file',
      },
    );
  } else {
    inputs.push({
      name: 'doc',
      labelPath: 'postulationDocs.attachment.file',
      type: 'file',
    });
  }

  if (commentRequired) {
    inputs.push({
      name: 'comment',
      labelPath: '',
      type: 'textArea',
    });
  }
  return inputs;
}
