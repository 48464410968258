import { Modal, addToast } from '@octano/global-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { signDocumentation } from '../../../../api/requests/tuitionProcess';
import { DocumentTypes } from '../../../../types/signDocumentationTypes';
import SignFormFields from './SignFormFields';

type InputsFormSign = {
  pswSustainer: string;
  pswStudent: string;
};

type SignProps = {
  docToSign?: DocumentTypes;
  onClose: (signed?: boolean) => void;
};

const DocumentModal = (props: SignProps) => {
  const { docToSign, onClose } = props;
  const prefix = 'tuitionProcess.sign';
  const { t } = useTranslation();
  const methods = useForm<InputsFormSign>({
    mode: 'onSubmit',
  });

  const signDocument = async (values: InputsFormSign) => {
    if (!docToSign) {
      return;
    }
    const { error } = await signDocumentation({
      type: docToSign,
      ...values,
    });
    if (!error) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.msgSignSuccess`),
      });
      onClose(true);
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.msgSignError`),
      });
    }
  };

  return (
    <Modal
      isOpen={Boolean(docToSign)}
      toggle={() => onClose()}
      closeOnBackdrop={false}
    >
      <Row className="text-center">
        <Col xs={12} className="pb-3">
          <span className="fs-22 text-dark">
            {t(`${prefix}.signModalTitle`)}
          </span>
        </Col>
        <Col xs={12}>
          <p className="fs-16 fw-300">{t(`${prefix}.signModalSubtitle`)}</p>
        </Col>
      </Row>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(signDocument)}>
          <SignFormFields onPressCancel={() => onClose()} />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default DocumentModal;
