import { Button, Icon, addToast } from '@octano/global-ui';
import { useCallback } from 'react';
import { Card, Col, Row } from 'reactstrap';

import { changeDesistedStatusToInProcess } from '../../../../../api/requests/tuitionProcess';
import { useUserState } from '../../../../../hooks/useUserState';

interface DesistedProps {
  refresh: () => Promise<void>;
}

export default function Desisted({ refresh }: DesistedProps) {
  const { resetUserState } = useUserState();
  const changeStatus = useCallback(async () => {
    const {
      data: desistedStatusData,
    } = await changeDesistedStatusToInProcess();
    if (desistedStatusData) {
      if (desistedStatusData.deleted) {
        resetUserState();
      } else {
        refresh && refresh();
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Se ha cambiado el estado de la postulación',
        });
      }
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: 'Ha ocurrido un error al cambiar el estado de la postulación',
      });
    }
  }, [refresh, resetUserState]);

  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="text-center mx-auto w-100 py-4">
        <Col xs={12} className="pb-2">
          <Icon name="information" size="52px" color="secondary" />
        </Col>
        <Col xs={12} className="pt-4 text-dark fw-400">
          <p className="fs-22 ">Tu postulación ha sido desistida</p>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300 lh-30">
            Cierra sesión y podrás iniciar un nuevo proceso.
          </p>
        </Col>
      </Row>
      <div className="d-flex flex-column align-items-center" style={{ gap: 8 }}>
        <Button
          text="cerrar sesión"
          style={{ width: 300 }}
          onClick={() => changeStatus()}
        />
      </div>
    </Card>
  );
}
