import AuthContainer from '../../components/container/AuthContainer';
import LoginFormNoSuaProcess from './parts/LoginFormNoSuaProcess';

const AuthTuitionProcessNoSua = () => {
  // TODO: Validar estado del proceso
  return (
    <AuthContainer>
      <LoginFormNoSuaProcess />
    </AuthContainer>
  );
};

export default AuthTuitionProcessNoSua;
