import { Button, Modal, addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';

import { AxiosResult, AxiosResultDefaultError } from '../../../../api/request';
import {
  AuthenticationError,
  saveStudentFilePhoto,
} from '../../../../api/requests/tuitionProcess';
import { useTuitionProcess } from '../../TuitionProcessContext';
import StudentPhotoCrop, { StudentPhotoCropTexts } from './StudentPhotoCrop';
import StudentPhotoSelect, {
  StudentPhotoSelectTexts,
} from './StudentPhotoSelect';

export interface StudentPhotoModalProps {
  open?: boolean;
  onClose: () => void;
  currentPhoto?: string | null;
  texts: StudentPhotoModalTexts;
  onSave: (src: string | null) => void;
}

export interface FileAndSrc {
  file: File;
  src: string;
}

export interface StudentPhotoModalTexts {
  select: StudentPhotoSelectTexts & { cancel: string; save: string };
  crop: StudentPhotoCropTexts;
  fileAccept: string;
  fileReject: string;
  fileRejectedSize: string;
}

const StudentPhotoModal = (props: StudentPhotoModalProps) => {
  const {
    open = false,
    texts,
    currentPhoto,
    onClose,
    onSave: onSaveProp,
  } = props;

  const { postulationDetailId, onError } = useTuitionProcess();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<FileAndSrc | null>(null);

  const onSave = useCallback(async () => {
    setLoading(true);
    let result: AxiosResult<
      unknown,
      AuthenticationError | AxiosResultDefaultError
    > = await saveStudentFilePhoto(postulationDetailId, selectedPhoto?.file);

    if (result?.error) {
      const error = result.error;
      onError(error.code, () => {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        });
      });
    } else {
      onSaveProp(selectedPhoto?.src ?? null);
    }

    setLoading(false);
  }, [postulationDetailId, selectedPhoto, onError, t, onSaveProp]);

  const [step, setStep] = useState<'initial' | 'select' | 'crop'>('initial');
  const onSelect = useCallback((selection: FileAndSrc | null) => {
    if (selection) {
      setSelectedPhoto(selection);
      setStep('crop');
    } else {
      setSelectedPhoto(null);
      setStep('select');
    }
  }, []);

  const onSelectError = useCallback(() => {
    addToast({
      icon: 'error',
      color: 'danger',
      text: texts.fileRejectedSize,
    });
  }, [texts]);

  return (
    <Modal
      isOpen={open}
      toggle={onClose}
      unmountOnClose
      onOpened={() => {
        setStep('initial');
        setSelectedPhoto(null);
      }}
    >
      {step === 'initial' && (
        <>
          <StudentPhotoSelect
            currentPhoto={currentPhoto}
            texts={texts.select}
            onSelect={onSelect}
            onError={onSelectError}
          />
          <Row>
            <Col xs="12" lg="6" className="order-2 order-lg-1">
              <FormGroup>
                <Button
                  outlined
                  fullwidth
                  color="primary"
                  text={texts.select.cancel}
                  onClick={onClose}
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6" className="order-1 order-lg-2">
              <FormGroup>
                <Button
                  fullwidth
                  color="primary"
                  text={texts.select.save}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {step === 'crop' && (
        <StudentPhotoCrop
          texts={texts.crop}
          upImg={selectedPhoto?.src}
          onCompleted={(selection) => {
            if (!selection) {
              setStep('initial');
              setSelectedPhoto(null);
            } else {
              setStep('select');
              setSelectedPhoto(selection);
            }
          }}
        />
      )}
      {step === 'select' && (
        <>
          <StudentPhotoSelect
            currentPhoto={selectedPhoto?.src}
            texts={texts.select}
            onSelect={onSelect}
            onError={onSelectError}
          />
          <Row>
            <Col xs="12" lg="6" className="order-2 order-lg-1">
              <FormGroup>
                <Button
                  outlined
                  fullwidth
                  color="primary"
                  text={texts.select.cancel}
                  onClick={onClose}
                />
              </FormGroup>
            </Col>
            <Col xs="12" lg="6" className="order-1 order-lg-2">
              <FormGroup>
                <Button
                  fullwidth
                  color="primary"
                  text={texts.select.save}
                  onClick={onSave}
                  loading={loading}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default StudentPhotoModal;
