import { Button } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import { getCompleted } from '../../api/requests/tuitionProcess';
import { ReactComponent as CompletedImage } from '../../assets/svg/completed_image.svg';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useUserState } from '../../hooks/useUserState';
import DisplayError from '../info/DisplayError';
import Loading from '../info/Loading';

interface ProcessCompletedProps {
  prefix?: string;
}

const ProcessCompleted = ({
  prefix = 'tuitionProcess.completed',
}: ProcessCompletedProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const { setIsSessionExpired } = useUserState();
  const [helpEmail, setHelpEmail] = useState('');

  const { resetUserPostulationState } = useUserState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getStatus = useCallback(async () => {
    const { data, error } = await getCompleted();
    if (data) {
      setErrorLoading(undefined);
      setHelpEmail(data.helpEmail);
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === 'HTTP_ERROR' && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, setIsSessionExpired]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={getStatus}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <div style={{ alignSelf: 'center' }}>
        <Row className="text-center py-4">
          <Col xs={12}>
            <CompletedImage width="230px" />
          </Col>
          <Col xs={12} className="py-4">
            <span className="fs-20 text-dark">{t(`${prefix}.title`)}</span>
          </Col>
          <Col xs={12}>
            <p className="fs-18 fw-300">
              <Trans
                i18nKey={`${prefix}.body`}
                values={{ helpEmail }}
                components={{ emailLink: <span className="text-link" /> }}
              />
            </p>
          </Col>

          <Col xs={12}>
            <Button
              className="mx-auto"
              style={{ maxWidth: 694 }}
              text={t(`${prefix}.goToResume`)}
              onClick={() => {
                resetUserPostulationState();
                history.replace('/tuition-process');
              }}
              fullwidth
            />
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default ProcessCompleted;
