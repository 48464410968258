import { addToast } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { saveStudentFile } from '../../../../api/requests/tuitionProcess';
import DynamicForm from '../../../../components/DynamicForm/DynamicForm';
import DymanicHealthCareCampus from '../../../../components/DynamicForm/parts/DymanicHealthCareCampus';
import DynamicContact from '../../../../components/DynamicForm/parts/DynamicContact';
import DynamicContactCampus from '../../../../components/DynamicForm/parts/DynamicContactCampus';
import DynamicFamilyContactCampus from '../../../../components/DynamicForm/parts/DynamicFamilyContactCampus';
import HeaderStudentFullName from '../../../../components/tuitionProcess/HeaderStudentFullName';
import { useParameters } from '../../../../hooks/useParameters';
import { useStepState } from '../../../../hooks/useStepState';
import {
  educationalLevelOptions,
  familyGroupOptions,
  parentalSurvivalOptions,
} from '../../../../utils/selectOptions';
import { useTuitionProcess } from '../../../TuitionProcess/TuitionProcessContext';
import StudentBackgroundNoSua from './parts/StudentBackgroundNoSua';
import StudentBackgroundNoSuaCampus from './parts/StudentBackgroundNoSuaCampus';
import StudentFileLoader from './parts/StudentFileLoader';

const StudentFileNoSuaStep = () => {
  //Custom Hooks
  const { prefix: rootPrefix, onError } = useTuitionProcess();
  const { t } = useTranslation();
  const { nextStep } = useStepState();
  const {
    countryOptions,
    institutionTypeOptions,
    communeOptions,
    regionOptions,
  } = useParameters();

  //Step Prefix
  const prefix = useMemo(() => `${rootPrefix}.studentFile`, [rootPrefix]);

  //Submit form function
  const onSubmit = async (values: any) => {
    const responses = Object.entries(values)
      .map(([key, value]) => ({
        fieldName: key,
        value,
      }))
      .filter(({ value }) => value);

    /**
     * si el país es chile igual mando location y custom city
     */
    if (String(values.country?.label).toLowerCase().includes('chile')) {
      responses.push(
        { fieldName: 'location', value: values.commune?.label },
        { fieldName: 'customCity', value: values.city?.label ?? '-' },
      );
    }

    const { error } = await saveStudentFile({
      responses,
    });

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: 'Ha ocurrido un error al guardar la información',
      });
    } else {
      addToast({
        icon: 'success',
        color: 'success',
        text: 'Datos guardados correctamente',
      });
      nextStep();
    }
  };

  const sectionComponents = (photo: string | null) => {
    return {
      postulantInformation: (
        <StudentBackgroundNoSua
          photo={photo}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      postulantInformationCampus: (
        <StudentBackgroundNoSuaCampus
          photo={photo}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      academicContact: (
        <DynamicContact
          type="studentFile"
          prefix={`${prefix}.academicContactSection`}
          optionalFields={['addressExtra', 'phone']}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      academicContactCampus: (
        <DynamicContactCampus
          type="studentFile"
          prefix={`${prefix}.academicContactSection`}
          key={Math.floor(Math.random() * 100)}
          hasEmail={true}
        />
      ),
      healthCareCampus: (
        <DymanicHealthCareCampus
          type="studentFile"
          prefix={`${prefix}.healtCareSection`}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      familyContactCampus: (
        <DynamicFamilyContactCampus
          type="studentFile"
          key={Math.floor(Math.random() * 100)}
          prefix={`${prefix}.familyGroupSection`}
          disabled={false}
          hasEmail
        />
      ),
    };
  };

  return (
    <Card className="px-4 px-md-5 py-4">
      <StudentFileLoader {...{ onError }}>
        {({ defaultValues, photo, admissionType }) => (
          <>
            <Row className="pb-4 pb-md-0">
              <Col xs={12} lg={7} md={8} xl={9}>
                <HeaderStudentFullName prefix={prefix} />
              </Col>
              <Col xs={12} lg={5} md={4} xl={3} className="pt-3 pt-md-0">
                <div className="box-outlined w-100 ml-md-auto w-100">
                  <span className="text-secondary fw-700 text-uppercase">
                    {t(`${prefix}.admissionType`)}
                  </span>
                  <br />
                  {admissionType?.id && (
                    <span className="fs-16 text-secondary">
                      {t(`admissionTypes.${admissionType.id}`)}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
            <DynamicForm
              dynamicFormId={1}
              defaultValues={defaultValues}
              sectionComponents={sectionComponents(photo)}
              options={{
                countryHighSchool: countryOptions,
                typeHighSchool: institutionTypeOptions,
                regionFamily: regionOptions,
                communeFamily: communeOptions,
                healthCareSystem: regionOptions,
                healthCareProvider: communeOptions,
                fatherEducationalLevel: educationalLevelOptions,
                motherEducationalLevel: educationalLevelOptions,
                familyGroup: familyGroupOptions,
                parentalSurvival: parentalSurvivalOptions,
              }}
              onSubmit={onSubmit}
            />
          </>
        )}
      </StudentFileLoader>
    </Card>
  );
};

export default StudentFileNoSuaStep;
