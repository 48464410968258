import { PostulantPostulationState } from './userTypes';

export type WaitingListInformationResponse = {
  helpEmail: string;
  postulantEmail: string;
  postulantName: string;
  studyPlan: {
    id: number;
    name: string;
    campus: {
      id: number;
      name: string;
      code: string;
      communeId: number;
      addressExtra: string | null;
      addressNumber: string;
      addressStreet: string;
      createdAt: string;
      updatedAt: string;
    };
    schedule: {
      id: number;
      name: string;
      code: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    };
  };
};

export enum TuitionOriginCodesEnum {
  OnSite = 'OS', //matricula presencial
  Online = 'OL', //matricula online
}

export type StudyPlanQuotaResponse = {
  id: number;
  quota: number;
  campus: { id: number; name: string };
  schedule: { id: number; name: string };
  scopes: TuitionOriginCodesEnum[];
  studyPlanVersion: {
    id: number;
    name: string;
    degreeRequirements: any;
    entryRequirements: any;
    graduationRequirements: any;
  };
  helpEmail: string;
  helpPhone: string;
};

export interface TuitionProcessStatusResponse {
  studentFullName: string;
  postulantId: number;
  studentRut: string;
  hasPreassignedGratuity?: boolean;
  isOnlineTuition?: boolean;
  isOnlinePayment?: boolean;
  step: string;
  status?: PostulantPostulationState;
  postulationDetailId?: number;
  admissionMethod: 'con-sua' | 'sin-sua';
  admission?: {
    admissionId: number;
    studyPlanName: string;
    campus: { id: number; code: string; name: string };
    schedule: { id: number; code: string; name: string };
  };
}
