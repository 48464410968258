import { UserAction, UserActionTypes, UserState } from '../types/userTypes';
import { getJWT } from '../utils/auth';

export const initialState: UserState = {
  isLogged: getJWT() ? true : false,
  isSessionExpired: false,
  fullName: '',
  rut: '',
  statusPostulation: '',
  postulantId: 0,
  postulationDetail: null,
};

const userReducer = (state: UserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionTypes.RESET_POSTULATION_STATE:
      return {
        ...state,
        statusPostulation: initialState.statusPostulation,
        postulantId: initialState.postulantId,
        postulationDetail: initialState.postulationDetail,
      };

    case UserActionTypes.RESET_STATE:
      return {
        ...initialState,
        isLogged: false,
      };

    case UserActionTypes.SET_IS_LOGGED:
      return {
        ...state,
        isLogged: action.payload,
      };

    case UserActionTypes.SET_IS_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.payload,
      };

    case UserActionTypes.SET_FULL_NAME:
      return {
        ...state,
        fullName: action.payload,
      };

    case UserActionTypes.SET_USER_RUT:
      return {
        ...state,
        rut: action.payload,
      };

    case UserActionTypes.SET_STATUS_POSTULATION:
      return {
        ...state,
        statusPostulation: action.payload,
      };

    case UserActionTypes.SET_POSTULATION_DETAIL:
      return {
        ...state,
        postulationDetail: action.payload,
      };

    case UserActionTypes.SET_POSTULANT_ID:
      return {
        ...state,
        postulantId: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
