import { Dispatch } from 'react';

export enum UserActionTypes {
  RESET_POSTULATION_STATE = 'RESET_POSTULATION_STATE',
  RESET_STATE = 'RESET_STATE',
  SET_IS_LOGGED = 'SET_IS_LOGGED',
  SET_IS_SESSION_EXPIRED = 'SET_IS_SESSION_EXPIRED',
  SET_FULL_NAME = 'SET_FULL_NAME',
  SET_USER_RUT = 'SET_USER_RUT',
  SET_STATUS_POSTULATION = 'SET_STATUS_POSTULATION',
  SET_POSTULANT_ID = 'SET_POSTULANT_ID',
  SET_POSTULATION_DETAIL = 'SET_POSTULATION_DETAIL',
}

export enum PostulantPostulationState {
  POSTULANT_NOT_FOUND = 'POSTULANT_NOT_FOUND',
  NOT_IN_PROCESS = 'NOT_IN_PROCESS',
  IN_PROCESS = 'IN_PROCESS',
  IN_WAITING_LIST = 'IN_WAITING_LIST',
  PRE_STAGE = 'PRE_STAGE',
  STUDY_PLAN_CHANGE = 'STUDY_PLAN_CHANGE',
  ENROLLED = 'ENROLLED',
  DESISTED = 'DESISTED',
}

export enum POSTULATION_STATUS {
  IN_PROCESS = 'en_proceso',
  ENROLLED = 'matriculado',
  IN_WAITING_LIST = 'en_espera',
  DESISTED = 'desistido',
  RETRACTADO = 'retractado',
  CHANGE_STUDY_PLAN = 'cambio_de_plan_de_studio',
  NO_POSTULATION = 'sin_postulacion',
}

export type UserAction =
  | { type: UserActionTypes.RESET_POSTULATION_STATE }
  | { type: UserActionTypes.RESET_STATE }
  | { type: UserActionTypes.SET_IS_LOGGED; payload: boolean }
  | { type: UserActionTypes.SET_IS_SESSION_EXPIRED; payload: boolean }
  | { type: UserActionTypes.SET_FULL_NAME; payload: string }
  | { type: UserActionTypes.SET_USER_RUT; payload: string }
  | { type: UserActionTypes.SET_STATUS_POSTULATION; payload: string }
  | { type: UserActionTypes.SET_POSTULANT_ID; payload: number }
  | {
      type: UserActionTypes.SET_POSTULATION_DETAIL;
      payload: PostulationDetailType | null;
    };

export type UserState = {
  isLogged: boolean;
  isSessionExpired: boolean;
  fullName: string;
  rut: string;
  statusPostulation: string;
  postulantId: number;
  postulationDetail: PostulationDetailType | null;
};

export type PostulationDetailType = {
  id: number;
  admissionId: number;
  campus: {
    id: number;
    name: string;
  };
  schedule: {
    id: number;
    name: string;
  };
  studyPlanName: string;
  admissionMethod: 'con-sua' | 'sin-sua';
  status?: PostulantPostulationState;
};

export type UserContextType = {
  state: UserState;
  dispatch: Dispatch<UserAction>;
};
