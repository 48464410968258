import { Button, CheckInput, addToast } from '@octano/global-ui';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { saveTermsAndConditions } from '../../../../api/requests/tuitionProcess';
import ConfirmModal from '../../../../components/confirm/ConfirmModal';
import { useStepState } from '../../../../hooks/useStepState';
import { useUserState } from '../../../../hooks/useUserState';

export interface TermsAndConditionsFormProps {
  hasGratuity: boolean;
}

type InputsForm = {
  check: boolean;
};

/**
 * Formulario del paso términos y condiciones del proceso de matrícula
 */
const TermsAndConditionsForm = ({
  hasGratuity,
}: TermsAndConditionsFormProps) => {
  const prefix = 'tuitionProcess.termsAndConditions';
  const modalPrefix = useMemo(() => {
    if (hasGratuity) {
      return `${prefix}.modalConfirmWithGratuity`;
    }
    return `${prefix}.modalConfirm`;
  }, [hasGratuity]);
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<InputsForm>();
  const { nextStep } = useStepState();
  const { setIsSessionExpired } = useUserState();

  const onSubmit = async () => {
    const { error } = await saveTermsAndConditions();
    if (!error) {
      nextStep();
    } else if (error && error.code === 'HTTP_ERROR' && error.status === 401) {
      setIsSessionExpired(true);
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.nextStepError`),
      });
    }
  };

  return (
    <ConfirmModal
      title={t(`${modalPrefix}.title`)}
      body={t(`${modalPrefix}.body`)}
      cancel={t(`${modalPrefix}.cancel`)}
      confirm={t(`${modalPrefix}.accept`)}
      onConfirmed={onSubmit}
    >
      {({ requestConfirm }) => (
        <Form onSubmit={handleSubmit(requestConfirm)}>
          <Row>
            <Col xs={12}>
              <CheckInput
                name="check"
                label={t(`${prefix}.check`)}
                control={control}
                rules={{ required: t(`${prefix}.checkRequired`).toString() }}
              />
            </Col>
          </Row>
          <Row className="pb-5">
            <Col xs={12} lg={4} className="ml-auto">
              <Button type="submit" text={t(`${prefix}.nextBtn`)} fullwidth />
            </Col>
          </Row>
        </Form>
      )}
    </ConfirmModal>
  );
};

export default TermsAndConditionsForm;
