import { Button, Icon } from '@octano/global-ui';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useCallback, useMemo } from 'react';
import { Card, Col, Row } from 'reactstrap';

import { getStatusTuitionProcess } from '../../../../../api/requests/tuitionProcess';
import MailtoButton from '../../../../../components/mailButton/MailtoButton';
import { useStepState } from '../../../../../hooks/useStepState';
import { useUserState } from '../../../../../hooks/useUserState';

interface LoginWithPassportProps {
  email?: string;
  phone?: string;
  academy?: string;
}
export default function LoginWithPassport(props: LoginWithPassportProps) {
  const { prevStep } = useStepState();
  const { postulationDetail, setPostulationDetail } = useUserState();

  /**
   * Cuando se matricula con el flujo original y
   * selecciona carrera en el caso de caer en
   * una modalidad donde no se permite  pasapporte
   * le actualizo la data del postulationDetail,
   * por si retrocede y cambia de carrera.
   */
  const getStatus = useCallback(async () => {
    const fetchData = async () => {
      const { data } = await getStatusTuitionProcess();
      if (data) {
        setPostulationDetail(
          data.postulationDetailId && data.admission
            ? {
                id: data.postulationDetailId,
                ...data.admission,
                admissionMethod: data.admissionMethod,
                status: data.status,
              }
            : null,
        );
        prevStep();
      }
    };

    if (!postulationDetail) {
      await fetchData();
    } else {
      prevStep();
    }
  }, [postulationDetail, prevStep, setPostulationDetail]);

  const phone = useMemo(
    () => parsePhoneNumber(props.phone ?? '+5699999999').formatInternational(),
    [props.phone],
  );

  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="text-center mx-auto w-100 py-4">
        <Col xs={12} className="pb-2">
          <Icon name="information" size="52px" color="secondary" />
        </Col>
        <Col xs={12} className="pt-4 text-dark fw-400">
          <p className="fs-22 ">
            Este programa debe ser inscrito de manera presencial.
          </p>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300 lh-30">
            Para continuar con la matrícula debes dirigirte al {props.academy} o
            selecciona un curso online. También puedes contactarte al teléfono{' '}
            <a href={`tel:${phone}`}>{phone}</a> o mediante correo electrónico
          </p>
        </Col>
      </Row>

      <div className="d-flex flex-column align-items-center" style={{ gap: 8 }}>
        <Button
          text="cambiar selección"
          style={{ width: 300 }}
          onClick={() => getStatus()}
        />
        {props.email && props.academy && (
          <MailtoButton email={props.email} clientName={props.academy} />
        )}
      </div>
    </Card>
  );
}
