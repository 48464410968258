import { Button, DisplayInfo, addToast } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

import {
  changeToDesisted,
  changeToWaiting,
} from '../../../../api/requests/tuitionProcess';
import { useTuitionProcessStatus } from '../StudentFile/parts/TuitionProcessStatusLoader';

export function QuotaExceed() {
  const { refresh } = useTuitionProcessStatus();
  const { t } = useTranslation();
  const prefix = 'tuitionProcessNoSua.quotaExceed';
  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      body: t(`${prefix}.body`),
      changeStudyPlan: t(`${prefix}.changeStudyPlan`),
      setWaitingList: t(`${prefix}.setWaitingList`),
    }),
    [t],
  );

  const onChangeStudyPlan = async () => {
    const { error } = await changeToDesisted();
    if (!error) {
      refresh();
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.nextStepError`),
      });
    }
  };

  const onChangeToWaiting = async () => {
    const { error } = await changeToWaiting();
    if (!error) {
      refresh();
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.nextStepError`),
      });
    }
  };

  return (
    <>
      <DisplayInfo title={texts.title} textBody={texts.body} />
      <Container fluid>
        <Row className="pt-3">
          <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2">
            <Button
              onClick={() => onChangeStudyPlan()}
              text={texts.changeStudyPlan}
              size="md"
              outlined
              fullwidth
            />
          </Col>
          <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2">
            <Button
              onClick={() => onChangeToWaiting()}
              text={texts.setWaitingList}
              size="md"
              fullwidth
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
