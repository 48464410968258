import { Container } from 'reactstrap';

import { ReactComponent as AddemicProductLogo } from '../assets/addemic/logo_expanded_white.svg';
import RoutesByLayout from '../components/routes/RoutesByLayout';
import { IS_ADDEMIC } from '../config/constants';
import { PathsLayouts } from '../config/routes';

const CertificateValidationLayout = () => {
  return (
    <>
      <div className="g-navbar">
        <div className="g-brand-logo">
          {IS_ADDEMIC ? (
            <AddemicProductLogo style={{ maxHeight: 30 }} />
          ) : (
            <img
              src="/tenant/logo_expanded_white.svg"
              alt="tenant_logo"
              style={{ maxHeight: 90 }}
            />
          )}
        </div>
      </div>
      <Container className="pt-4 pb-5">
        <RoutesByLayout pathLayout={PathsLayouts.certificateValidation} />
      </Container>
    </>
  );
};

export default CertificateValidationLayout;
