import {
  ParametersAction,
  ParametersActionType,
  ParametersState,
} from '../types/parametersTypes';

export const initialState: ParametersState = {
  countryOptions: [],
  nationalityOptions: [],
  communeOptions: [],
  cityOptions: [],
  regionOptions: [],
  institutionTypeOptions: [],
  parameterErrors: {},
};

const parametersReducer = (
  state: ParametersState,
  action: ParametersAction,
): ParametersState => {
  switch (action.type) {
    case ParametersActionType.SET_COUNTRIES:
      return {
        ...state,
        countryOptions: action.payload,
      };

    case ParametersActionType.SET_NATIONALITIES:
      return {
        ...state,
        nationalityOptions: action.payload,
      };

    case ParametersActionType.SET_REGIONS:
      return {
        ...state,
        regionOptions: action.payload,
      };

    case ParametersActionType.SET_CITIES:
      return {
        ...state,
        cityOptions: action.payload,
      };

    case ParametersActionType.SET_COMMUNES:
      return {
        ...state,
        communeOptions: action.payload,
      };

    case ParametersActionType.SET_INSTITUTION_TYPES:
      return {
        ...state,
        institutionTypeOptions: action.payload,
      };

    case ParametersActionType.SET_PARAMETER_ERROR:
      return {
        ...state,
        parameterErrors: action.payload,
      };

    default:
      return state;
  }
};

export default parametersReducer;
