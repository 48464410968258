import { Select, TextInput } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useValidations } from '../../../hooks/useValidations';
import {
  HAS_INSURANCE,
  HEALTH_CARE_OTHER,
  ISAPRE,
  healthCareProviderOptions,
  healthCareSystemOptions,
  healthInsuranceOptions,
} from '../../../utils/selectOptions';
import { SectionTitle } from '../../text';

type DynamicContactType = 'studentFile' | 'sustainerFile';

type ContactSectionProps = {
  prefix: string;
  type: DynamicContactType;
  optionalFields?: string[];
  fieldNames?: {
    healthCareSystem: string;
    healthCareProvider: string;
    otherHealthCare: string;
    healthCareContributorID: string;
    healthInsurance: string;
    healthInsuranceContributorID: string;
    phoneNumberHealthCare: string;
  };
  disabled?: boolean;
};

/**
 * Para usar este componente tu formulario debe estar envuelto en su FormProvider
 * para poder acceder a la información del formulario a través de useFormContext
 * Los nombres agregados en fieldNames se relacionan a los campos que utilizaste
 * al definir tu formulario.
 */
const DymanicHealthCareCampus = ({
  prefix,
  type,
  optionalFields = [],
  fieldNames = {
    healthCareSystem: 'healthCareSystem',
    healthCareProvider: 'healthCareProvider',
    otherHealthCare: 'otherHealthCare',
    healthCareContributorID: 'healthCareContributorID',
    healthInsurance: 'healthInsurance',
    healthInsuranceContributorID: 'healthInsuranceContributorID',
    phoneNumberHealthCare: 'phoneNumberHealthCare',
  },
  disabled = false,
}: ContactSectionProps) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();
  const {
    validateRut,
    validateTextNotEmpty,
    validatePhone,
    msgValidations,
  } = useValidations();

  const selectedHealthCareSystem = watch(fieldNames.healthCareSystem);
  const selectedHealthInsurance = watch(fieldNames.healthInsurance);
  const ownSustainer = watch('ownSustainer');

  const optional = (value: string, fieldName: string) => {
    if (optionalFields.includes(fieldName)) {
      return undefined;
    }
    return validateTextNotEmpty(value);
  };

  const handleChangeOwnSutainer = useCallback(
    (next: boolean) => {
      if (!next) {
        setValue(fieldNames?.healthCareSystem, '');
        setValue(fieldNames?.healthCareProvider, '');
        setValue(fieldNames?.otherHealthCare, '');
        setValue(fieldNames?.healthCareContributorID, '');
        setValue(fieldNames?.healthInsurance, '');
        setValue(fieldNames?.healthInsuranceContributorID, '');
        setValue(fieldNames?.phoneNumberHealthCare, '');
      }
    },
    [
      fieldNames?.healthCareProvider,
      fieldNames?.healthCareSystem,
      fieldNames?.healthCareContributorID,
      fieldNames?.healthInsurance,
      fieldNames?.healthInsuranceContributorID,
      fieldNames?.otherHealthCare,
      fieldNames?.phoneNumberHealthCare,
      setValue,
    ],
  );

  useEffect(() => {
    if (type === 'sustainerFile' && !ownSustainer) {
      handleChangeOwnSutainer(ownSustainer);
    }
  }, [handleChangeOwnSutainer, ownSustainer, type]);

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames.healthCareSystem}
            label={t(`${prefix}.${fieldNames.healthCareSystem}`)}
            options={healthCareSystemOptions}
            isSearchable={false}
            control={control}
            disabled={disabled || ownSustainer === true}
          />
        </Col>
        {selectedHealthCareSystem?.value === ISAPRE && (
          <Col className="pb-3" xs={12} lg={4}>
            <Select
              name={fieldNames.healthCareProvider}
              label={t(`${prefix}.${fieldNames.healthCareProvider}`)}
              options={healthCareProviderOptions}
              isSearchable={false}
              control={control}
              disabled={disabled || ownSustainer === true}
              rules={{
                validate: {
                  require: (value) => {
                    return optional(value, fieldNames.healthCareProvider);
                  },
                },
              }}
              shouldUnregister
            />
          </Col>
        )}
        {selectedHealthCareSystem?.value === HEALTH_CARE_OTHER && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames.otherHealthCare}
              label={t(`${prefix}.${fieldNames.otherHealthCare}`)}
              control={control}
              disabled={disabled || ownSustainer === true}
              shouldUnregister
              rules={{ required: msgValidations.required }}
            />
          </Col>
        )}
        {selectedHealthCareSystem?.value && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames.healthCareContributorID}
              label={t(`${prefix}.${fieldNames.healthCareContributorID}`)}
              control={control}
              disabled={disabled || ownSustainer === true}
              formatter="rut"
              shouldUnregister
              rules={{
                required: msgValidations.required,
                validate: {
                  rut: validateRut,
                },
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames.healthInsurance}
            label={t(`${prefix}.${fieldNames.healthInsurance}`)}
            options={healthInsuranceOptions}
            control={control}
            disabled={disabled || ownSustainer === true}
            isSearchable={false}
            rules={{
              validate: {
                require: (value) => {
                  if (
                    !value &&
                    (selectedHealthCareSystem || selectedHealthInsurance)
                  ) {
                    return msgValidations.required;
                  }
                  return undefined;
                },
              },
            }}
          />
        </Col>
        {selectedHealthInsurance?.value === HAS_INSURANCE && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={fieldNames.healthInsuranceContributorID}
                label={t(
                  `${prefix}.${fieldNames.healthInsuranceContributorID}`,
                )}
                control={control}
                disabled={disabled || ownSustainer === true}
                formatter="rut"
                shouldUnregister
                rules={{
                  required: msgValidations.required,
                  validate: {
                    rut: validateRut,
                  },
                }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={fieldNames.phoneNumberHealthCare}
                label={t(`${prefix}.${fieldNames.phoneNumberHealthCare}`)}
                tooltip={t(`${prefix}.tooltipPhoneNumberHealthCare`)}
                control={control}
                disabled={disabled || ownSustainer === true}
                formatter="phone"
                shouldUnregister
                rules={{
                  validate: validatePhone,
                }}
                maxLength={15}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default DymanicHealthCareCampus;
