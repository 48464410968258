import { useState } from 'react';

export const useLoadingState = () => {
  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState<
    'CONNECTION' | 'HTTP_ERROR' | string
  >();

  return { loading, setLoading, errorLoading, setErrorLoading };
};
