interface MailtoButtonProps {
  email: string;
  clientName: string;
  subjectTo?: string;
}

const createMailtoLink = ({
  email,
  clientName,
  subjectTo,
}: MailtoButtonProps) => {
  const subject = encodeURIComponent(
    subjectTo ?? 'Matrícula estudiantes del extranjero',
  );
  const body = encodeURIComponent(
    `
    Buenos días, ${clientName}
    Me interesa matricularme a un curso presencial/semipresencial en su Centro de Estudios. 
    Sin embargo, al ser de otro país y no disponer de carnet de identidad chileno, me pongo en contacto con ustedes.
    Aquí dejo la información solicitada y quedo atento a sus comentarios!

    Curso deseado:
    Nombre(s):
    Apellido(s):
    Fecha de Nacimiento:
    País de origen:
    Número de pasaporte:
    Método de pago deseado:
    Tipo de pago deseado (Contado o en cuotas):

    Saludos cordiales`,
  );

  return `mailto:${email}?Subject=${subject}&Body=${body}`;
};

const MailtoButton = ({ email, clientName, subjectTo }: MailtoButtonProps) => {
  const mailtoLink = createMailtoLink({
    email,
    clientName,
    subjectTo,
  });

  return (
    <a
      className="g-button false  btn btn-outline-primary btn-lg "
      style={{ width: 300 }}
      href={mailtoLink}
    >
      <div className="pt-2">
        <span className="ml-2 text-uppercase">Enviar Correo electrónico</span>
      </div>
    </a>
  );
};

export default MailtoButton;
