import { Formatters } from '@octano/global-ui/dist/utils/formatters';
import { isValidNumber } from 'libphonenumber-js';
import { clean, validate } from 'rut.js';
import validator from 'validator';

enum ValidationType {
  Min = 'min',
  Max = 'max',
  Required = 'required',
  Pattern = 'pattern',
  MinLength = 'minLength',
  MaxLength = 'maxLength',
  Type = 'type',
}

interface FieldProperties {
  visible?: boolean;
  readonly?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  maxLength?: number;
  formatter?: keyof typeof Formatters;
  tooltip?: string;
}

interface ValidationRules {
  [ValidationType.Min]?: number;
  [ValidationType.Max]?: number;
  [ValidationType.Required]?: boolean;
  [ValidationType.Pattern]?: string;
  [ValidationType.MinLength]?: number;
  [ValidationType.MaxLength]?: number;
  [ValidationType.Type]?: 'rut' | 'phone' | 'email';
}

export interface FormField {
  component: 'text' | 'select' | 'textarea';
  name: string;
  label: string;
  placeholder?: string;
  dependsOn?: string[]; // Array de nombres de campos en los que depende este campo
  properties?: FieldProperties;
  validations?: ValidationRules;
  grid: {
    md: number;
    xs: number;
  };
}

export interface FormSection {
  title: string;
  component: string;
  fields: FormField[];
}

type ValidationFunction = (value: any) => string | undefined;

function getRequiredValidation(): ValidationFunction {
  return (value: any) => {
    if (
      value === null ||
      value === undefined ||
      (typeof value === 'string' && value.trim() === '')
    ) {
      return 'Este campo es obligatorio';
    }
    return undefined;
  };
}

function getRutValidation(): ValidationFunction {
  return (value: any) => {
    return value && typeof value === 'string' && !validate(clean(value))
      ? 'El RUT debe tener formato de RUT'
      : undefined;
  };
}

function getPhoneValidation(): ValidationFunction {
  return (phone: any) => {
    return phone &&
      typeof phone === 'string' &&
      phone.trim() !== '' &&
      isValidNumber(phone.replaceAll(' ', ''))
      ? undefined
      : 'El teléfono debe tener formato +XXXXXXXXXXX';
  };
}

function getEmailValidation(): ValidationFunction {
  return (email: any) => {
    return email && typeof email === 'string' && validator.isEmail(email)
      ? undefined
      : 'El correo debe tener formato de correo';
  };
}

export function validationSchema(validations?: ValidationRules) {
  const rules: Record<string, ValidationFunction> = {};
  if (validations) {
    for (const [key, value] of Object.entries(validations)) {
      switch (key as ValidationType) {
        case ValidationType.Required:
          rules[ValidationType.Required] = getRequiredValidation();
          break;
        case ValidationType.Type:
          switch (value as 'rut' | 'phone' | 'email') {
            case 'rut':
              rules[ValidationType.Type] = getRutValidation();
              break;
            case 'phone':
              rules[ValidationType.Type] = getPhoneValidation();
              break;
            case 'email':
              rules[ValidationType.Type] = getEmailValidation();
              break;
          }
          break;
      }
    }
  }
  return Object.keys(rules).length === 0 ? undefined : { validate: rules };
}
