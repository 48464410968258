import { Icon, SingleCardContainer } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { CardBody, CardTitle } from '../../../components/text';
import { formatDateTimeText } from '../../../utils/dates';

type ITuitionProcess = {
  id: number;
  type: string;
  startedAt: string;
  endedAt: string;
  createdAt: string;
  updatedAt: string;
};

type AuthMessagesProps = {
  tuitionProcess?: ITuitionProcess;
  helpEmail?: string;
};

const AuthMessages = ({ tuitionProcess, helpEmail }: AuthMessagesProps) => {
  const prefix = 'statusTuitionProcess';
  const { t } = useTranslation();

  const getCardInfo = () => {
    // Hay un proceso que está por comenzar
    if (tuitionProcess) {
      return (
        <Col xs={12} className="text-center">
          <CardTitle>{t(`${prefix}.nextProcessTitle`)}</CardTitle>
          <CardBody>
            {t(`${prefix}.nextProcessMsg`, {
              startedAt: formatDateTimeText(tuitionProcess.startedAt),
              endedAt: formatDateTimeText(tuitionProcess.endedAt),
            })}
          </CardBody>
          <p className="text-center pt-3 text-primary fs-18 lh-30 fw-700">
            {t(`${prefix}.nextProcessImportant`)}
          </p>
        </Col>
      );
    }
    // No hay procesos por comenzar por lo que te entrega correo para más información
    if (helpEmail) {
      return (
        <Col xs={12} className="text-center">
          <CardTitle>{t(`${prefix}.endedProcessTitle`)}</CardTitle>
          <CardBody>
            {t(`${prefix}.endedProcessMsg`)}{' '}
            <span className="text-primary text-link">{helpEmail}</span>
          </CardBody>
        </Col>
      );
    }
    // Error al recuperar los procesos
    return (
      <Col xs={12} className="text-center">
        <CardTitle>{t(`${prefix}.errorTitle`)}</CardTitle>
        <CardBody>{t(`${prefix}.errorMsg`)}</CardBody>
      </Col>
    );
  };

  return (
    <SingleCardContainer>
      <Row className="text-center">
        <Col xs={12} className="py-4">
          <Icon name="warning" size="42px" color="secondary" />
        </Col>
      </Row>
      {getCardInfo()}
    </SingleCardContainer>
  );
};

export default AuthMessages;
