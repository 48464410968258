import {
  Button,
  CheckInput,
  DateInput,
  Select,
  SelectOptionType,
  SingleCardContainer,
  TextInput,
  addToast,
} from '@octano/global-ui';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { clean } from 'rut.js';

import { requestSavePostulant } from '../../../api/requests/tuitionProcess';
import { ReactComponent as AddemicProductLogo } from '../../../assets/addemic/logo_expanded.svg';
import { ReactComponent as CampusProductLogo } from '../../../assets/campus/logo_expanded.svg';
import { IS_ADDEMIC } from '../../../config/constants';
import { useParameters } from '../../../hooks/useParameters';
import { useUserState } from '../../../hooks/useUserState';
import { useValidations } from '../../../hooks/useValidations';
import { SavePostulantBody } from '../../../types/postulantTypes';
import { setJWT } from '../../../utils/auth';
import { formatDate } from '../../../utils/dates';
import RegistrationModalError, {
  RegistrationModalErrorMethods,
} from './RegistrationModalError';

export type RegistrationInputs = {
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  birthday: Date;
  hasPassport: boolean;
  rut: string;
  passportNumber: string;
  passportCountry: SelectOptionType;
  phone: string;
  email: string;
  studyPlan: string;
};
const prefix = 'registration';

function RegistrationForm() {
  const modalErrorRef = useRef<RegistrationModalErrorMethods>(null);
  //hooks
  const { setIsLogged } = useUserState();
  const { t } = useTranslation();
  const { state: defaultValues } = useLocation<{
    rut?: string;
    hasPassport?: boolean;
    passportCountry?: SelectOptionType;
    passportNumber?: string;
  }>();
  const methods = useForm<RegistrationInputs>({ defaultValues });
  const { control, watch } = methods;
  const history = useHistory();
  const { countryOptions } = useParameters();
  const {
    msgValidations,
    validateRut,
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const hasPassport = watch('hasPassport');
  const formatBodyData = (values: RegistrationInputs): SavePostulantBody => {
    return {
      type: values.hasPassport ? 'passport' : 'rut',
      names: values.names,
      rut: values.rut ? clean(values.rut) : undefined,
      passportNumber: values?.passportNumber,
      passportCountry: values?.passportCountry?.value
        ? values.passportCountry.value.toString()
        : undefined,
      birthday: formatDate(values.birthday, 'YYYY-MM-DD'),
      paternalLastName: values.paternalLastName,
      maternalLastName: values.maternalLastName,
      paternalPreference: true,
      email: values.email,
      phone: values.phone,
    };
  };

  const onSubmit = async (values: RegistrationInputs) => {
    const requestParams = formatBodyData(values);
    const { data, error } = await requestSavePostulant(requestParams);
    if (error) {
      modalErrorRef?.current?.open(error?.data?.message);
    }
    if (data) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.saveSuccess`),
      });
      setJWT(data.accessToken);
      setIsLogged(true);
    }
  };

  const backToLogin = () => {
    history.push('/auth/tuition-process');
  };

  return (
    <>
      <SingleCardContainer
        brandLogoColor={
          IS_ADDEMIC ? <AddemicProductLogo /> : <CampusProductLogo />
        }
      >
        <div className="d-flex justify-content-center">
          {IS_ADDEMIC ? (
            <AddemicProductLogo style={{ maxHeight: 80 }} />
          ) : (
            <img
              src="/tenant/logo_expanded.svg"
              alt="tenant_logo"
              style={{ maxHeight: 100 }}
            />
          )}
        </div>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="px-4 pt-4">
            <Col xs={12} className="pt-2 pb-4 text-center">
              <p>{t(`${prefix}.form.body`)}</p>
            </Col>
            {!hasPassport ? (
              <Col xs={12}>
                <TextInput
                  name="rut"
                  label={t(`${prefix}.form.rut`)}
                  control={control}
                  formatter="rut"
                  shouldUnregister={true}
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      rut: validateRut,
                    },
                  }}
                />
              </Col>
            ) : (
              <>
                <Col xs={12} md={6} className="pr-md-1">
                  <Select
                    name="passportCountry"
                    label={t(`${prefix}.form.passportCountry`)}
                    control={control}
                    options={countryOptions}
                    shouldUnregister={true}
                    isClearable={false}
                    rules={{ required: msgValidations.required }}
                  />
                </Col>
                <Col xs={12} md={6} className="pl-md-1">
                  <TextInput
                    name="passportNumber"
                    label={t(`${prefix}.form.passportNumber`)}
                    control={control}
                    shouldUnregister={true}
                    rules={{ validate: validateTextNotEmpty }}
                  />
                </Col>
              </>
            )}

            <Col xs={12}>
              <CheckInput
                name="hasPassport"
                label={'Ingresar Pasaporte'}
                control={control}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="names"
                label={t(`${prefix}.form.names`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="paternalLastName"
                label={t(`${prefix}.form.paternalLastName`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="maternalLastName"
                label={t(`${prefix}.form.maternalLastName`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>

            <Col xs={12}>
              <DateInput
                name="birthday"
                label={t(`${prefix}.form.birthday`)}
                control={control}
                rules={{ required: msgValidations.required }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="phone"
                label={t(`${prefix}.form.phone`)}
                control={control}
                formatter="phone"
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    phone: validatePhone,
                  },
                }}
                placeholder={t(`common.placeholder.cellphone`)}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="email"
                label={t(`${prefix}.form.email`)}
                control={control}
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    email: validateEmail,
                  },
                }}
              />
            </Col>
            <Col xs={12} className="pt-4">
              <Button
                type="submit"
                text={t(`${prefix}.form.registrationBtn`)}
                fullwidth
              />
            </Col>
          </Row>
        </Form>
        <Row>
          <Col xs={12} className="pt-4 text-center">
            <Link to="/auth" className="text-link">
              {t(`${prefix}.goBackToLogin`)}
            </Link>
          </Col>
        </Row>
      </SingleCardContainer>

      <RegistrationModalError
        ref={modalErrorRef}
        hasPassport={hasPassport}
        onBackToLogin={backToLogin}
      />
    </>
  );
}

export default RegistrationForm;
