import {
  CheckInput,
  DateInput,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useParameters } from '../../hooks/useParameters';

export type LoginInputs = {
  birthday: Date;
  hasPassport: boolean;
  rut: string;
  passportNumber: string;
  passportCountry: SelectOptionType;
};

type LoginFormFieldProps = {
  hassPassport: boolean;
  control: Control<LoginInputs>;
};

const LoginFormFields = ({ hassPassport, control }: LoginFormFieldProps) => {
  const prefix = 'loginTuitionProcess';
  const { t } = useTranslation();
  const { countryOptions } = useParameters();

  return (
    <>
      <Row>
        {!hassPassport ? (
          <Col xs={12}>
            <TextInput
              name="rut"
              label={t(`${prefix}.labelRut`)}
              control={control}
              formatter="rut"
              shouldUnregister={true}
            />
          </Col>
        ) : (
          <>
            <Col xs={12} md={6} className="pr-md-1">
              <Select
                name="passportCountry"
                label={t(`${prefix}.labelPassportCountry`)}
                control={control}
                options={countryOptions}
                shouldUnregister={true}
                isClearable={false}
              />
            </Col>
            <Col xs={12} md={6} className="pl-md-1">
              <TextInput
                name="passportNumber"
                label={t(`${prefix}.labelPassportNumber`)}
                control={control}
                shouldUnregister={true}
              />
            </Col>
          </>
        )}
      </Row>

      <div className="pb-2">
        <CheckInput
          name="hasPassport"
          label={t(`${prefix}.labelCheckPassport`)}
          control={control}
        />
      </div>

      <div className="pt-2">
        <DateInput
          name="birthday"
          label={t(`${prefix}.labelBirthday`)}
          control={control}
        />
      </div>
    </>
  );
};

export default LoginFormFields;
