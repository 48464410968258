import { AxiosError } from 'axios';

export default function isAxiosError<T = any>(e: any): e is AxiosError<T> {
  return (
    e &&
    typeof e === 'object' &&
    e.hasOwnProperty('isAxiosError') &&
    e.isAxiosError === true
  );
}
