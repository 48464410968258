import { Box, Button } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { ReactComponent as CompletedImage } from '../../../../assets/svg/completed_image.svg';
import { ReactComponent as ResumenImage } from '../../../../assets/svg/with_tuition_process.svg';
import { POSTULATION_STATUS } from '../../../../types/userTypes';
import { Student } from '../../interfaces/online';

interface SearchStudentOnSiteSingleTuitionProps {
  student: Student;
  preload: () => void;
}

export const SearchStudentOnSiteSingleTuition = ({
  student,
  preload,
}: SearchStudentOnSiteSingleTuitionProps) => {
  const prefix = 'StudentOnline.singleTuition';
  const { t } = useTranslation();
  const history = useHistory();

  const currentStatus = useMemo(
    () =>
      student.newAdmission
        ? student.newAdmission.postulationDetailStatus
        : student.status,
    [student.newAdmission, student.status],
  );

  if (currentStatus === POSTULATION_STATUS.NO_POSTULATION) {
    return (
      <Row className="text-center py-4">
        <Col xs={12}>
          <CompletedImage width="230px" />
        </Col>

        <Col xs={12} className="py-4">
          <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-2">
            {t(`${prefix}.without.title`)}
          </h2>
          <p className="fs-18 fw-300">{t(`${prefix}.without.subtitle`)}</p>
        </Col>

        <Col xs={12}>
          <p className="fs-18 fw-300">{t(`${prefix}.with.body`)}</p>
          <Button
            className="mx-auto mt-4"
            style={{ maxWidth: 694 }}
            text={t(`${prefix}.actions.start`)}
            onClick={() => history.replace('/tuition-process/steps')}
            fullwidth
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row className="text-center py-4">
      <Col xs={12}>
        <ResumenImage width="230px" />
      </Col>

      <Col xs={12} className="py-4">
        <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-2">
          {t(`${prefix}.with.title`)}
        </h2>
        <p className="fs-18 fw-300">{t(`${prefix}.with.subtitle`)}</p>
      </Col>

      <Col xs={12}>
        <Box
          className="mt-2"
          style={{ minWidth: 400, maxWidth: 500 }}
          body={
            <Row>
              <Col xs={12} className="text-left">
                <p className="fw-700 text-uppercase m-0">Programa</p>
              </Col>
              <Col xs={12} className="text-left">
                <p className="m-0">{student.studyPlanVersion_name}</p>
              </Col>
            </Row>
          }
          title=""
          color="secondary"
          variant="outlined"
        />
      </Col>

      <Col xs={12}>
        <p className="fs-18 fw-300">{t(`${prefix}.with.body`)}</p>
      </Col>
    </Row>
  );
};
