import { SelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { format } from 'rut.js';

import { getCosigner } from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import StepBackButton from '../../../../components/tuitionProcess/StepBackButton';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { useParameters } from '../../../../hooks/useParameters';
import { useUserState } from '../../../../hooks/useUserState';
import { CosignerStepResponse } from '../../../../types/cosignerTypes';
import { getSelectedOption } from '../../../../utils/selectFormat';
import {
  RELATIONSHIP_OWN_SUSTAINER,
  maritalStatusOptions,
  relationshipOptions,
} from '../../../../utils/selectOptions';
import CosignerForm, { FieldsCosignerForm } from './CosignerForm';

const formatDefaultValues = (
  data: CosignerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
): DefaultValues<FieldsCosignerForm> => {
  const { cosigner } = data;
  const selectedCommune = getSelectedOption(
    cosigner?.communeId,
    communeOptions,
  );
  return {
    names: cosigner?.names,
    paternalLastName: cosigner?.paternalLastName,
    maternalLastName: cosigner?.maternalLastName,
    rut: cosigner?.rut ? format(cosigner.rut) : '',
    addressStreet: cosigner?.addressStreet,
    addressNumber: cosigner?.addressNumber,
    addressExtra: cosigner?.addressExtra ?? '',
    commune: selectedCommune,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    phone: cosigner?.phone ?? '',
    cellphone: cosigner?.cellPhone,
    email: cosigner?.email,
    checkSustainer: cosigner?.rut === data.sustainer.rut ? true : undefined,
  };
};

const formatDataSustainerAsCosigner = (
  data: CosignerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countryOptions: SelectOptionType[],
  nationalityOptions: SelectOptionType[],
): FieldsCosignerForm => {
  const { sustainer } = data;
  const selectedCommune = getSelectedOption(
    sustainer?.communeId,
    communeOptions,
  );
  return {
    relationship:
      relationshipOptions.find(
        (row) => row.value === RELATIONSHIP_OWN_SUSTAINER,
      ) || null,
    hasPassport:
      sustainer?.passportNumber && sustainer.passportNumber !== ''
        ? true
        : false,
    occupation: sustainer.occupation || '',
    names: sustainer.names,
    paternalLastName: sustainer.paternalLastName,
    maternalLastName: sustainer.maternalLastName,
    rut: sustainer.rut ? format(sustainer.rut) : '',
    passportCountry: getSelectedOption(
      sustainer?.passportCountry?.id,
      countryOptions,
    ),
    passportNumber: sustainer.passportNumber || '',
    addressStreet: sustainer.addressStreet,
    addressNumber: sustainer.addressNumber,
    addressExtra: sustainer.addressExtra ?? '',
    commune: selectedCommune ?? null,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions) ?? null,
    phone: sustainer.phone ?? '',
    cellphone: sustainer.cellPhone,
    email: sustainer.email,
    checkSustainer: true,
    maritalStatus:
      getSelectedOption(sustainer.maritalStatusId, maritalStatusOptions) ||
      null,
    nationality:
      getSelectedOption(sustainer?.nationality?.id, nationalityOptions) || null,
  };
};

/**
 * Paso "Datos Aval" del proceso de matrícula
 */
const Cosigner = () => {
  const prefix = 'tuitionProcess.cosigner';
  const { t } = useTranslation();
  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();
  const [defaultValues, setDefaultValues] = useState<
    DefaultValues<FieldsCosignerForm>
  >();
  const { fullName, setIsSessionExpired } = useUserState();
  const [dataSustainer, setDataSustainer] = useState<FieldsCosignerForm>();
  const {
    communeOptions,
    regionOptions,
    countryOptions,
    nationalityOptions,
  } = useParameters();

  const getCosignerStepInfo = useCallback(async () => {
    const { data, error } = await getCosigner();
    if (data) {
      setDefaultValues(
        formatDefaultValues(data, communeOptions, regionOptions),
      );
      setDataSustainer(
        formatDataSustainerAsCosigner(
          data,
          communeOptions,
          regionOptions,
          countryOptions,
          nationalityOptions,
        ),
      );
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === 'HTTP_ERROR' && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    setIsSessionExpired,
    nationalityOptions,
    communeOptions,
    regionOptions,
    countryOptions,
  ]);

  useEffect(() => {
    if (loading && communeOptions.length > 0 && regionOptions.length > 0) {
      getCosignerStepInfo();
    }
  }, [loading, getCosignerStepInfo, communeOptions, regionOptions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12}>
          <StepBackButton />
        </Col>
        <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
          <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
          <br />
          <span className="fs-18 text-primary fw-600">{fullName}</span>
        </Col>
      </Row>
      <CosignerForm
        defaultValues={defaultValues}
        dataSustainer={dataSustainer}
      />
    </Card>
  );
};

export default Cosigner;
