import { SearchPostulant, Student } from '../interfaces/online';

export const isMultiTuition = (
  data: SearchPostulant[] | SearchPostulant,
): data is SearchPostulant[] => {
  return Array.isArray(data);
};

export const studentDataAdapter = (student: SearchPostulant): Student => {
  const { Admission, New_admission, ...data } = student;
  return {
    hasMultiTuition: data.hasMultiTuition,
    postulant_id: data.Postulant_id,
    rut: data.Postulant_rut ? data.Postulant_rut : '',
    passportNumber: data.Postulant_passportNumber
      ? data.Postulant_passportNumber
      : '',
    country: '',
    names: data.Postulant_names,
    lastnamePather: data.Postulant_paternalLastName,
    lastnameMother: data.Postulant_maternalLastName,
    email: data.Contact_email,
    phone: data.Contact_cellPhone,
    birthdate: data.Postulant_birthday,
    status: Admission.Details_status,
    admissionTypeId: Admission.Postulation_admissionTypeId,
    studyPlanVersion_name: Admission.StudyPlanVersion_name,
    campus_name: Admission.Campus_name,
    schedule_name: Admission.Schedule_name,
    Details_id: Admission.Details_id,
    newAdmission: New_admission
      ? {
          postulationDetailId: New_admission.Details_id,
          postulationDetailStatus: New_admission.Details_status,
          admissionTypeId: New_admission.Postulation_admissionTypeId,
          studyPlanVersionName: New_admission.StudyPlanVersion_name,
          campusName: New_admission.Campus_name,
          scheduleName: New_admission.Schedule_name,
        }
      : undefined,
  };
};
