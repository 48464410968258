import {
  Button,
  CheckInput,
  DateInput,
  Select,
  SelectOptionType,
  SingleCardContainer,
  TextInput,
  addToast,
  showDialogInfo,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import { clean, format } from 'rut.js';

import { requestSavePostulantAndCareer } from '../../../api/requests/tuitionProcess';
import { ReactComponent as AddemicProductLogo } from '../../../assets/addemic/logo_expanded.svg';
import { ReactComponent as CampusProductLogo } from '../../../assets/campus/logo_expanded.svg';
import { IS_ADDEMIC } from '../../../config/constants';
import { useParameters } from '../../../hooks/useParameters';
import { useUserState } from '../../../hooks/useUserState';
import { useValidations } from '../../../hooks/useValidations';
import { SavePostulantBody } from '../../../types/postulantTypes';
import {
  StudyPlanQuotaResponse,
  TuitionOriginCodesEnum,
} from '../../../types/tuitionProcessResponseTypes';
import { setJWT } from '../../../utils/auth';
import { formatDate } from '../../../utils/dates';
import RegistrationModalError, {
  RegistrationModalErrorMethods,
} from './RegistrationModalError';

export type RegistrationInputs = {
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  birthday: Date;
  hasPassport: boolean;
  rut: string;
  passportNumber: string;
  passportCountry: SelectOptionType;
  phone: string;
  email: string;
  studyPlan: string;
};
const prefix = 'registration';

function RegistrationWithCareerForm({
  career,
}: {
  career: StudyPlanQuotaResponse;
}) {
  const modalErrorRef = useRef<RegistrationModalErrorMethods>(null);
  //hooks
  const { state: defaultValues } = useLocation<{
    rut?: string;
    hasPassport?: boolean;
    passportCountry?: SelectOptionType;
    passportNumber?: string;
  }>();
  const { setIsLogged, setStatusPostulation } = useUserState();
  const { t } = useTranslation();
  const methods = useForm<RegistrationInputs>({ defaultValues });
  const { control, watch } = methods;
  const history = useHistory();
  const { countryOptions } = useParameters();
  const {
    msgValidations,
    validateRut,
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const hasPassport = watch('hasPassport');
  const [confirm, setConfirm] = useState(false);
  const [confirming, setConfirming] = useState<boolean>(false);

  const notAvailableTuition = useMemo(
    () =>
      career?.scopes &&
      !career?.scopes?.includes(TuitionOriginCodesEnum.Online),
    [career?.scopes],
  );

  /**
   * Se utiliza para inscribir al alumno en lista de espera luego de confirmar.
   */
  const acceptWaitingList = async () => {
    setConfirming(true);
    const { data } = await requestSavePostulantAndCareer(
      formatBodyData(methods.getValues(), true),
    );
    if (data) {
      setJWT(data.accessToken);
      setIsLogged(true);
    }

    modalErrorRef?.current?.open('QUOTA_EXCEEDED');
    setConfirming(false);
  };

  const formatBodyData = useCallback(
    (
      values: RegistrationInputs,
      acceptsWaitingList = false,
    ): SavePostulantBody => {
      return {
        type: values.hasPassport ? 'passport' : 'rut',
        names: values.names,
        rut: values.rut ? clean(values.rut) : undefined,
        passportNumber: values?.passportNumber,
        passportCountry: values?.passportCountry?.value
          ? values.passportCountry.value.toString()
          : undefined,
        birthday: formatDate(values.birthday, 'YYYY-MM-DD'),
        paternalLastName: values.paternalLastName,
        maternalLastName: values.maternalLastName,
        paternalPreference: true,
        email: values.email,
        phone: values.phone,
        admissionId: career?.id,
        acceptsWaitingList,
      };
    },
    [career?.id],
  );

  const handleRedirectToRegularRegistration = useCallback(
    (values?: RegistrationInputs) => {
      return history.push(
        `/auth/registration`,
        values
          ? {
              rut: values.rut ? format(values.rut!) : null,
              hasPassport: values.hasPassport,
              passportNumber: values.passportNumber,
              passportCountry: values.passportCountry,
            }
          : undefined,
      );
    },
    [history],
  );

  const showNotAvailableTuition = useCallback(
    (values?: RegistrationInputs) => {
      showDialogInfo({
        icon: {
          name: 'information',
          color: 'primary',
        },
        title: t(`${prefix}.modalNotAvailableTuition.title`),
        subtitle: t(`${prefix}.modalNotAvailableTuition.subtitle`, {
          phone: career?.helpPhone,
          email: career?.helpEmail,
        }),
        btnConfirm: {
          text: t(`${prefix}.modalNotAvailableTuition.btn`),
          color: 'primary',
          onConfirm: () => {
            handleRedirectToRegularRegistration(values);
          },
        },
      });
    },
    [
      career?.helpEmail,
      career?.helpPhone,
      handleRedirectToRegularRegistration,
      t,
    ],
  );

  const onSubmit = async (values: RegistrationInputs) => {
    if (notAvailableTuition) {
      return showNotAvailableTuition(values);
    }
    const requestParams = formatBodyData(values);
    const { data, error } = await requestSavePostulantAndCareer(requestParams);
    if (error) {
      modalErrorRef?.current?.open(error?.data?.message);
    }
    if (data) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.saveSuccess`),
      });
      setJWT(data.accessToken);
      setIsLogged(true);
      if (requestParams.acceptsWaitingList) {
        setStatusPostulation('IN_WAITING_LIST');
      }
    }
  };

  const backToLogin = () => {
    history.push('/auth/tuition-process');
  };

  useEffect(() => {
    if (notAvailableTuition) {
      showNotAvailableTuition();
    }
  }, [notAvailableTuition, showNotAvailableTuition]);

  return (
    <>
      <SingleCardContainer
        brandLogoColor={
          IS_ADDEMIC ? <AddemicProductLogo /> : <CampusProductLogo />
        }
      >
        <div className="d-flex justify-content-center">
          {IS_ADDEMIC ? (
            <AddemicProductLogo style={{ maxHeight: 80 }} />
          ) : (
            <img
              src="/tenant/logo_expanded.svg"
              alt="tenant_logo"
              style={{ maxHeight: 100 }}
            />
          )}
        </div>

        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="px-4 pt-4">
            <Col xs={12} className="pt-2 pb-4 text-center">
              <p>{t(`${prefix}.form.body`)}</p>
            </Col>
            {!hasPassport ? (
              <Col xs={12}>
                <TextInput
                  name="rut"
                  label={t(`${prefix}.form.rut`)}
                  control={control}
                  formatter="rut"
                  shouldUnregister={true}
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      rut: validateRut,
                    },
                  }}
                  readOnly={Boolean(defaultValues)}
                />
              </Col>
            ) : (
              <>
                <Col xs={12} md={6} className="pr-md-1">
                  <Select
                    name="passportCountry"
                    label={t(`${prefix}.form.passportCountry`)}
                    control={control}
                    options={countryOptions}
                    shouldUnregister={true}
                    isClearable={false}
                    rules={{ required: msgValidations.required }}
                    disabled={Boolean(defaultValues)}
                  />
                </Col>
                <Col xs={12} md={6} className="pl-md-1">
                  <TextInput
                    name="passportNumber"
                    label={t(`${prefix}.form.passportNumber`)}
                    control={control}
                    shouldUnregister={true}
                    rules={{ validate: validateTextNotEmpty }}
                    readOnly={Boolean(defaultValues)}
                  />
                </Col>
              </>
            )}

            <Col xs={12}>
              <CheckInput
                name="hasPassport"
                label={'Ingresar Pasaporte'}
                control={control}
                disabled={Boolean(defaultValues)}
              />
            </Col>

            <Col xs={12}>
              <TextInput
                name="studyPlan"
                label={t(`${prefix}.form.studyPlan`)}
                disabled
                value={career.studyPlanVersion.name}
              />
            </Col>

            <Col xs={12}>
              <TextInput
                name="names"
                label={t(`${prefix}.form.names`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="paternalLastName"
                label={t(`${prefix}.form.paternalLastName`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="maternalLastName"
                label={t(`${prefix}.form.maternalLastName`)}
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>

            <Col xs={12}>
              <DateInput
                name="birthday"
                label={t(`${prefix}.form.birthday`)}
                control={control}
                rules={{ required: msgValidations.required }}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="phone"
                label={t(`${prefix}.form.phone`)}
                control={control}
                formatter="phone"
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    phone: validatePhone,
                  },
                }}
                placeholder={t(`common.placeholder.cellphone`)}
              />
            </Col>
            <Col xs={12}>
              <TextInput
                name="email"
                label={t(`${prefix}.form.email`)}
                control={control}
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    email: validateEmail,
                  },
                }}
              />
            </Col>
            <Col xs={12}>
              <CheckInput
                name="confirm"
                label={
                  'Confirmo estar de acuerdo con el programa y modalidad seleccionados'
                }
                value={confirm}
                onChange={() => setConfirm((old) => !old)}
              />
            </Col>
            <Col xs={12} className="pt-4">
              <Button
                type="submit"
                text={t(`${prefix}.form.registrationBtn`)}
                fullwidth
                loading={methods.formState.isSubmitting}
                disabled={!confirm}
              />
            </Col>
          </Row>
        </Form>
        <Row>
          <Col xs={12} className="pt-4 text-center">
            <Link to="/auth" className="text-link">
              {t(`${prefix}.goBackToLogin`)}
            </Link>
          </Col>
        </Row>
      </SingleCardContainer>

      <RegistrationModalError
        ref={modalErrorRef}
        loading={confirming}
        hasPassport={hasPassport}
        onBackToLogin={backToLogin}
        onGoToWaitlist={acceptWaitingList}
      />
    </>
  );
}

export default RegistrationWithCareerForm;
