import { Button, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useValidations } from '../../../../hooks/useValidations';

const SignFormFields = ({ onPressCancel }: { onPressCancel: () => void }) => {
  const prefix = 'tuitionProcess.sign';
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { validateTextNotEmpty } = useValidations();

  return (
    <>
      <Row className="px-4 py-3">
        <Col xs={12} className="py-3">
          <TextInput
            name="pswSustainer"
            type="password"
            label={t(`${prefix}.labelPswSustainer`)}
            control={control}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
              },
            }}
            shouldUnregister={true}
          />
        </Col>
        <Col xs={12} className="py-3">
          <TextInput
            name="pswStudent"
            type="password"
            label={t(`${prefix}.labelPswStudent`)}
            control={control}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
              },
            }}
            shouldUnregister={true}
          />
        </Col>
      </Row>
      <Row className="pt-3">
        <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2">
          <Button
            onClick={onPressCancel}
            text={t(`${prefix}.cancelSignModal`)}
            outlined
            fullwidth
          />
        </Col>
        <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2">
          <Button
            type="submit"
            text={t(`${prefix}.confirmSignModal`)}
            fullwidth
          />
        </Col>
      </Row>
    </>
  );
};

export default SignFormFields;
