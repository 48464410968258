import { Button, SelectOptionType, addToast } from '@octano/global-ui';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { saveStudentFile } from '../../../../api/requests/tuitionProcess';
import ContactSection from '../../../../components/form/ContactSection';
import { useStepState } from '../../../../hooks/useStepState';
import { useUserState } from '../../../../hooks/useUserState';
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from '../../../../types/studentFileSectionTypes';
import BackgroundSection from './BackgroundSection';
import EducationalBackgroundSection from './EducationalBackgroundSection';
import EmergencyContactSection from './EmergencyContactSection';
import HealthCareSection from './HealthCareSection';
import ParentalEducationSection from './ParentalEducationSection';

export type FieldsStudentFile = {
  sexID: SelectOptionType;
  maritalStatus: SelectOptionType;
  addressStreet: string;
  addressNumber: string;
  addressExtra: string;
  commune: SelectOptionType | null;
  region: SelectOptionType;
  phone: string;
  cellphone: string;
  email: string;
  addressStreetFamily: string;
  addressNumberFamily: string;
  addressExtraFamily: string;
  regionFamily: SelectOptionType;
  communeFamily: SelectOptionType | null;
  phoneFamily: string;
  cellphoneFamily: string;
  emailFamily: string;
  emergencyNames: string;
  mobilePhoneNumberEmergency: string;
  emailEmergency: string;
  fatherEducationalLevel: SelectOptionType;
  motherEducationalLevel: SelectOptionType;
  familyGroup: SelectOptionType;
  parentalSurvival: SelectOptionType;
  healthCareSystem: SelectOptionType;
  healthCareProvider: SelectOptionType;
  healthCareContributorID: string;
  healthInsurance: SelectOptionType;
  healthInsuranceContributorID: string;
  phoneNumberHealthCare: string;
  otherHealthCare: string;
};

type StudentFileFormProps = {
  backgroundData?: BackgroundSectionType;
  educationalBackground?: EducationalBackgroundType;
  defaultValues?: DefaultValues<FieldsStudentFile>;
};

const StudentFileForm = ({
  backgroundData,
  educationalBackground,
  defaultValues,
}: StudentFileFormProps) => {
  const { nextStep } = useStepState();
  const prefix = 'tuitionProcess.studentFile';
  const { t } = useTranslation();
  const { setIsSessionExpired } = useUserState();
  const methods = useForm<FieldsStudentFile>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: FieldsStudentFile) => {
    if (values.commune && values.communeFamily) {
      const { data, error } = await saveStudentFile({
        background: {
          sexId: +values.sexID.value,
          maritalStatusId: +values.maritalStatus.value,
        },
        contact: {
          addressStreet: values.addressStreet,
          addressNumber: values.addressNumber,
          addressExtra: values.addressExtra,
          communeId: +values.commune.value,
          phone: values.phone,
          cellPhone: values.cellphone,
          email: values.email,
        },
        familyContact: {
          addressStreet: values.addressStreetFamily,
          addressNumber: values.addressNumberFamily,
          addressExtra: values.addressExtraFamily,
          communeId: +values.communeFamily.value,
          phone: values.phoneFamily,
          cellPhone: values.cellphoneFamily,
          email: values.emailFamily,
        },
        emergencyContact: {
          name: values.emergencyNames,
          cellPhone: values.mobilePhoneNumberEmergency,
          email: values.emailEmergency,
        },
        healthCare: {
          systemId: +values.healthCareSystem.value,
          providerId: values?.healthCareProvider
            ? +values.healthCareProvider.value
            : null,
          contributorRut: values.healthCareContributorID,
          hasInsurance: Boolean(values.healthInsurance.value),
          insuranceContributorRut: values.healthInsuranceContributorID ?? null,
          insuranceCellPhone: values.phoneNumberHealthCare,
          otherSystem: values.otherHealthCare,
        },
        parentalEducationalBackground: {
          educationalLevelPaternalId: +values.fatherEducationalLevel.value,
          educationalLevelMaternalId: +values.motherEducationalLevel.value,
          familyGroupId: +values.familyGroup.value,
          parentalSurvivalId: +values.parentalSurvival.value,
        },
      });

      if (data === '') {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.saveSuccess`),
        });
        nextStep();
      } else if (error) {
        if (error.code === 'HTTP_ERROR' && error.status === 401) {
          setIsSessionExpired(true);
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.saveError`),
          });
        }
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {/* Secciones del formulario */}
        <BackgroundSection data={backgroundData} />
        <EducationalBackgroundSection
          educationalBackground={educationalBackground}
        />
        <ContactSection prefix={`${prefix}.academicContactSection`} />
        <ContactSection
          prefix={`${prefix}.familyGroupSection`}
          fieldNames={{
            addressStreet: 'addressStreetFamily',
            addressNumber: 'addressNumberFamily',
            addressExtra: 'addressExtraFamily',
            region: 'regionFamily',
            city: 'cityFamily',
            commune: 'communeFamily',
            phone: 'phoneFamily',
            cellphone: 'cellphoneFamily',
          }}
        />
        <EmergencyContactSection />
        <ParentalEducationSection />
        <HealthCareSection />

        <Row className="pb-5 pt-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              type="submit"
              text={t(`${prefix}.nextBtn`)}
              loading={methods.formState.isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default StudentFileForm;
