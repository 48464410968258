import BaseLoader from './BaseLoader';
import DocumentationNoSua from './DocumentationNoSua';

/**
 * Componente que corresponde a la vista principal del paso de contrato y subida de archivos
 */
const Documentation = () => {
  return (
    <BaseLoader>
      {({ requestedDocuments }) => (
        <DocumentationNoSua requestedDocuments={requestedDocuments} />
      )}
    </BaseLoader>
  );
};

export default Documentation;
