import { TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import SectionTitle from '../../../../components/text/SectionTitle';
import { useValidations } from '../../../../hooks/useValidations';
import { FieldsStudentFile } from './StudentFileForm';

/**
 * Sección de contacto de emergencia
 */
const EmergencyContactSection = () => {
  const { control } = useFormContext<FieldsStudentFile>();
  const prefix = 'tuitionProcess.studentFile.emergencyContactSection';
  const { t } = useTranslation();
  const {
    validateEmail,
    validateTextNotEmpty,
    validatePhone,
  } = useValidations();

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="emergencyNames"
            label={t(`${prefix}.names`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            maxLength={250}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="mobilePhoneNumberEmergency"
            label={t(`${prefix}.mobilePhoneNumber`)}
            control={control}
            formatter="phone"
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                phone: validatePhone,
              },
            }}
            placeholder={t(`common.placeholder.cellphone`)}
            maxLength={15}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="emailEmergency"
            label={t(`${prefix}.email`)}
            control={control}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                email: validateEmail,
              },
            }}
            maxLength={100}
          />
        </Col>
      </Row>
    </>
  );
};

export default EmergencyContactSection;
